import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Divider,
    Select,
    MenuItem,
    InputBase,
    Button,
    Input,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { backArrow, checkbox, selectedCheckbox, blankCheckbox, emptyDashboardImg, successImg, failedQue } from "./assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export const configJSON = require("./config");
// Customizable Area End

import QuestionBankCreationController, {
    Props
} from "./QuestionBankCreationController";

export default class QuestionBankCreation extends QuestionBankCreationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    successCompanyDetailDialog = () => {
        return (
            <Dialog
                open={this.state.openSuccessDialog}
                onClose={this.closeSuccessDialog}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                        borderRadius: "8px 8px 32px 8px"
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="successDialogTitle">
                            {!this.state.questionBankData?.data?.id ?
                                "Failed to Create Questions" : "Question Created"
                            }
                        </Typography>
                        <IconButton
                            data-test-id="close_success"
                            aria-label="close"
                            onClick={this.closeSuccessDialog}
                            className="successDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="successImageBox">
                            <img
                                src={!this.state.questionBankData?.data?.id ?
                                    failedQue.default : successImg.default
                                }
                                alt="Success Image"
                                height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            padding: "14px 34px",
                            fontSize: "20px",
                            fontWeight: 700,
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {!this.state.questionBankData?.data?.id ?
                            "Sorry! Failure to Create Questions" : "Question Created Successfully!"
                        }
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            padding: "0px 58px",
                            fontSize: "18px",
                            fontWeight: 400,
                            lineHeight: "26px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {!this.state.questionBankData?.data?.id ?
                            "We encountered an issue while trying to create your questions. This might be due to incomplete information or a system error." :
                            "Your question has been added to the Question Bank."
                        }
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ?
                            "doneButtonsBox" : "doneButtonsBoxArabic"}`}>
                            <Button
                                variant="contained"
                                color="primary"
                                data-test-id="navigate_to_list_question"
                                onClick={!this.state.questionBankData?.data?.id ?
                                    this.navigateToQuesCreation : this.navigateToListQuestion
                                }
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "doneButton" : "doneButtonArabic"}`}
                            >
                                {!this.state.questionBankData?.data?.id ?
                                    "Retry" : "Done"
                                }
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    progressQuestionScreen = () => {
        return (
            <Wrapper>
                <Box className="questionItemMainBox">
                    <Grid container className="questionCardContainer" justifyContent={"flex-start"}>
                        <Grid item xs={12} className="questionCardItem">
                            <Card className="questionCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <EmptyWrapper>
                                            <Grid container className="gridContainer">
                                                <Grid item xs={12}>
                                                    <Box className="compNoDataImageBox">
                                                        <img src={emptyDashboardImg.default} />

                                                        <Box className="dotsProgress">
                                                            {[0, 1, 2, 3, 4, 5, 6].map((obj, index) => <Box key={index}
                                                                sx={{
                                                                    width: "7.5px",
                                                                    height: "7.5px",
                                                                    borderRadius: "50%",
                                                                    backgroundColor: "#059669",
                                                                    marginRight: "5px",
                                                                    transform: `${this.handleCondition(index === this.state.active, "scale(1.5)", "scale(1)")}`
                                                                }}></Box>)}
                                                        </Box>

                                                        <Box width="50%" mt={2}>
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={this.state.exportProgress}
                                                                className="questionProgreassbar"
                                                            />
                                                        </Box>

                                                        <Typography className="completeText">
                                                            {this.state.exportProgress}% Complete,
                                                        </Typography>
                                                        <Typography className="completeText">
                                                            {this.analyzingText()}
                                                        </Typography>

                                                        <Typography className="hangTightText">
                                                            Hang tight! We're generating your questions...
                                                        </Typography>
                                                        <Typography className="hangTightSubText">
                                                            This may take a few moments, depending on the complexity and number of questions
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </EmptyWrapper>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    competencyList = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="questionLabel">
                        1. Competency <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {this.state.generateBtnClicked && this.state.selectedCompetencies.length === 0 && configJSON.errorText}
                    </Typography>
                </Box>
                <Select
                    className="competencyDropDown"
                    data-test-id="select_competencies"
                    multiple
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedCompetencies}
                    onChange={this.handleCompetenciesChange}
                    renderValue={(selected) =>
                        selected.length > 0 ? selected.join(', ') : (
                            <div className="dropDownplaceHolder">
                                Choose Competencies
                            </div>
                        )
                    }
                    IconComponent={KeyboardArrowDownIcon}
                    input={<InputBase />}
                >
                    {this.state.competencies.data?.map((item) => {
                        const selected = this.state.selectedCompetencies.includes(item.attributes.competency_name);

                        return (
                            <MenuItem key={item.id} value={item.attributes.competency_name}
                                data-test-id={`competency_${item.attributes.competency_name}`}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "22px",
                                    margin: "16px",
                                    color: "#0F172A",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    '&.Mui-selected': {
                                        backgroundColor: "#E1F5F9",
                                        border: "1px solid #044352",
                                        borderRadius: "8px"
                                    }
                                }}
                                onClick={() => this.handleCheckbox(item.attributes.competency_name)}
                            >
                                <img
                                    src={selected ?
                                        selectedCheckbox.default :
                                        checkbox.default
                                    }
                                    style={{ marginRight: "10px" }}
                                />
                                {item.attributes.competency_name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Wrapper>
        )
    }

    questionType = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="questionLabel">
                        2. Question Type <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {this.state.generateBtnClicked && this.state.selectedQuestionType.length === 0 && configJSON.errorText}
                    </Typography>
                </Box>

                <Box sx={{ fontFamily: 'Urbanist' }}>
                    {this.state.questionType.map((type) => {
                        const selected = this.state.selectedQuestionType.includes(type.id);
                        return (
                            <Box
                                key={type.id}
                                display="flex"
                                alignItems="center"
                                mb={1}
                                className={selected ? "SelectedOptionStyle" : "queOptionsStyle"}
                                sx={{ fontFamily: 'Urbanist' }}
                                data-test-id={`question_type_${type.name}`}
                                onClick={() => this.handleQuestionTypeChange(type.id)}
                            >
                                <img
                                    src={selected ? selectedCheckbox.default : blankCheckbox.default}
                                    alt="checkbox"
                                    className="checkBoxStyle"
                                />
                                <Typography>{type.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>

            </Wrapper>
        )
    }

    questionFormat = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="questionLabel">
                        3. Question Format <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {this.state.generateBtnClicked && this.state.selectedQuestionFormat.length === 0 && configJSON.errorText}
                    </Typography>
                </Box>


                <Box sx={{ fontFamily: 'Urbanist' }}>
                    {this.state.questionFormat.map((type) => {
                        const selected = this.state.selectedQuestionFormat.includes(type.id);
                        return (
                            <Box
                                key={type.id}
                                display="flex"
                                alignItems="center"
                                mb={1}
                                className={selected ? "SelectedOptionStyle" : "queOptionsStyle"}
                                sx={{ fontFamily: 'Urbanist' }}
                                data-test-id={`question_format_${type.name}`}
                                onClick={() => this.handleQuestionFormatChange(type.id)}
                            >
                                <img
                                    src={selected ? selectedCheckbox.default : blankCheckbox.default}
                                    alt="checkbox"
                                    className="checkBoxStyle"
                                />
                                <Typography>{type.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Wrapper>
        )
    }

    numberOfQuestions = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="questionLabel">
                        4. Number of Questions in Question Bank <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {this.state.generateBtnClicked && !this.state.noOfQuestions && configJSON.errorText}
                        {this.state.noOfQueErrorMsg && <span>{this.state.noOfQueErrorMsg}</span>}
                    </Typography>
                </Box>

                <Input
                    data-test-id="number_of_questions"
                    className="enterInputField"
                    disableUnderline
                    placeholder="Enter the number of Questions"
                    value={this.state.noOfQuestions}
                    onChange={this.noOfQuestionChange}
                    autoComplete="off"
                    inputProps={{
                        maxLength: 3,
                    }}
                />
            </Wrapper>
        )
    }

    difficultyLevel = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="questionLabel">
                        5. Difficulty Level <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {this.state.generateBtnClicked && this.state.selectedDifficultyLevel.length === 0 && configJSON.errorText}
                    </Typography>
                </Box>

                <Box sx={{ fontFamily: 'Urbanist' }}>
                    {this.state.difficultyLevel.map((type) => {
                        const selected = this.state.selectedDifficultyLevel.includes(type.id);
                        return (
                            <Box
                                key={type.id}
                                display="flex"
                                alignItems="center"
                                data-test-id={`difficulty_level_${type.name}`}
                                mb={1}
                                className={selected ? "SelectedOptionStyle" : "queOptionsStyle"}
                                sx={{ fontFamily: 'Urbanist' }}
                                onClick={() => this.difficultyLevelChange(type.id)}
                            >
                                <img
                                    src={selected ? selectedCheckbox.default : blankCheckbox.default}
                                    alt="checkbox"
                                    className="checkBoxStyle"
                                />
                                <Typography>{type.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Wrapper>
        )
    }

    focusArea = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="questionLabel">
                        6. Focus Areas <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {this.state.generateBtnClicked && this.state.selectedFocusArea.length === 0 && configJSON.errorText}
                    </Typography>
                </Box>

                <Box sx={{ fontFamily: 'Urbanist' }}>
                    {this.state.focusAreas.map((type) => {
                        const selected = this.state.selectedFocusArea.includes(type.id);
                        return (
                            <Box
                                key={type.id}
                                display="flex"
                                alignItems="center"
                                mb={1}
                                data-test-id={`focus_area_${type.name}`}
                                className={selected ? "SelectedOptionStyle" : "queOptionsStyle"}
                                sx={{ fontFamily: 'Urbanist' }}
                                onClick={() => this.focusAreaChange(type.id)}
                            >
                                <img
                                    src={selected ? selectedCheckbox.default : blankCheckbox.default}
                                    alt="checkbox"
                                    className="checkBoxStyle"
                                />
                                <Typography>{type.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Wrapper>
        )
    }

    assessmentObjective = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="questionLabel">
                        7. Assessment Objective <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {this.state.generateBtnClicked && !this.state.assessmentObjectives && configJSON.errorText}
                    </Typography>
                </Box>
                <textarea
                    data-test-id="assessment_objectives"
                    className="assObjectiveField"
                    placeholder="Describe the objective of the assessment..."
                    value={this.state.assessmentObjectives}
                    onChange={this.handleAssessmentObjective}
                />
                <Box className="assObjectiveDesc">
                    <Typography></Typography>
                    <Typography className="assObjectiveLimit">{this.state.assessmentObjectives.length}/5000</Typography>
                </Box>
            </Wrapper>
        )
    }

    cancelQuestionButton = () => {
        return (
            <Wrapper>
                <Button
                    className="cancelQueBtn"
                    data-test-id="cancel_details"
                    onClick={this.navigateToDashboard}
                >
                    <Typography className="cancelQueText">
                        Cancel
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    generateQuestionButton = () => {
        return (
            <Wrapper>
                <Button
                    className="generateQueBtn"
                    data-test-id="generate_question"
                    onClick={this.handleGenerateQuestion}
                >
                    <Typography className="generateQueText">
                        Generate Questions
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    createQuestionBankHeader = () => {
        return (
            <Wrapper>
                <Box className="questionItemMainBox">
                    <Grid container className="questionCardContainer" justifyContent={"flex-start"}>
                        <Grid item xs={12} className="questionCardItem">
                            <Card className="questionCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="cardDetailPadding">
                                            <Typography className="questionCardTitle">
                                                New Competency
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="contentPadding">
                                        <Grid item xs={12}>
                                            {this.competencyList()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.questionType()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.questionFormat()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.numberOfQuestions()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.difficultyLevel()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.focusArea()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.assessmentObjective()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.cancelQuestionButton()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.generateQuestionButton()}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="backArrowImg"
                                        data-test-id="back_to_dashboard"
                                        onClick={this.state.progrssbarScreen ? this.navigateToQuesCreation : this.navigateToDashboard}
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                                    <Typography className="questionTitle">
                                        {this.questionTitle()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className="compMainHeaderGrid">
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.state.progrssbarScreen ?
                                        this.progressQuestionScreen() :
                                        this.createQuestionBankHeader()
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.successCompanyDetailDialog()}
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    width: "100%",
    marginTop: "42px",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "30px",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .questionTitle": {
        fontWeight: 600,
        lineHeight: "30px",
        fontFamily: "Urbanist",
        fontSize: "25px",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
})

export const EmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    height: "calc(100vh - 180px)",
    "& .hangTightSubText": {
        padding: "0px 58px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "26px",
        color: "#0F172A",
        marginTop: "8px",
        fontFamily: "Urbanist",
        textAlign: "center"
    },
    "& .completeText": {
        fontFamily: "Urbanist",
        padding: "0px 58px",
        color: "#059669",
        marginTop: "8px",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .dotsProgress": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "16px",
        marginBottom: "20px",
        gap: "7.5px",
        "@media (max-width: 768px)": {
            gap: "5px",
        },
        "@media (max-width: 480px)": {
            gap: "3px",
        },
    },
    "& .questionProgreassbar": {
        borderRadius: 4,
        height: 10,
        backgroundColor: '#e0e0e0',
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#059669',
        },
    },
    "& .hangTightText": {
        marginTop: "50px",
        fontFamily: "Urbanist",
        padding: "0px 58px",
        fontSize: "20px",
        color: "#0F172A",
        fontWeight: 700,
        lineHeight: "26px",
        textAlign: "center",
    },
    "& .compNoDataImageBox": {
        flexDirection: "column",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .gridContainer": {
        height: "100%"
    }
})

export const Wrapper = styled(Box)({
    width: "100%",
    "& .cardDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .successDialogTitle": {
        fontSize: "24px",
        fontFamily: "Urbanist",
        fontWeight: 700,
        lineHeight: "32px",
        margin: "8px 16px",
        color: "#1E1E1E"
    },
    "& .questionItemMainBox": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        overflow: "hidden"
    },
    "& .doneButtonsBox": {
        display: "flex",
        justifyContent: "flex-end",
        margin: "16px 0px",
        paddingRight: "16px"
    },
    "& .doneButtonsBoxArabic": {
        paddingLeft: "16px",
        display: "flex",
        justifyContent: "flex-end",
        margin: "16px 0px"
    },
    "& .questionCardContainer": {
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .successDialogClose": {
        top: 16,
        position: 'absolute',
        color: "#334155"
    },
    "& .successImageBox": {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "center"
    },
    "& .questionCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
    },
    "& .questionCardDescBox": {
        width: "55vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .doneButton": {
        width: "30%",
        height: "56px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "8px",
        padding: "16px 24px",
        gap: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .doneButtonArabic": {
        width: "120px",
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .dropDownplaceHolder": {
        fontSize: 16,
        color: "#DCDCDC",
        fontWeight: 400
    } as React.CSSProperties,
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .questionCardTitle": {
        lineHeight: "24px",
        color: "#0444352",
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .errorStyleMainbox": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .checkBoxStyle": {
        marginRight: "10px",
        width: "20px",
        height: "20px",
    },
    "& .assObjectiveDesc": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .assObjectiveLimit": {
        fontSize: "12px",
        fontWeight: 400,
        display: "flex",
        justifyContent: "flex-end",
        fontFamily: "Urbanist",
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .enterInputField": {
        fontWeight: 400,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        border: '1px solid #E2E8F0',
        fontSie: '16px',
        textAlign: "left",
        borderRadius: '8px',
        height: '54px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .errorStyle": {
        fontSize: "0.85rem",
        color: "#F30202",
        marginBottom: "0.5rem",
    },
    "& .competencyDropDown": {
        border: '1px solid #E2E8F0',
        height: '66px',
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .queOptionsStyle": {
        cursor: "pointer",
        "&:hover": { backgroundColor: "#f9f9f9" },
        border: '1px solid #E2E8F0',
        height: '54px',
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '2px 16px',
        "& .MuiTypography-root": {
            fontFamily: 'Urbanist',
            fontSize: "14px",
            fontWeight: 400,
            linHheight: "22px",
        },
    },
    "& .SelectedOptionStyle": {
        cursor: "pointer",
        border: '1px solid #044352',
        backgroundColor: "#F1F5F9",
        height: '54px',
        fontSie: '14px',
        fontWeight: 700,
        fontFamily: 'Urbanist',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '2px 16px',
        "& .MuiTypography-root": {
            fontFamily: 'Urbanist',
            fontSize: "14px",
            fontWeight: 700,
            linHheight: "22px",
        },
    },
    "& .questionLabel": {
        color: "#344054",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        linHheight: "22px",
        marginBottom: "10px"
    },
    "& .cancelQueText": {
        lineHeight: "24px",
        color: "#000000",
        fontFamily: "Urbanist",
        fontWeight: 700,
        textTransform: "none",
        fontSize: "16px"
    },
    "& .assObjectiveField": {
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        borderRadius: "8px",
        border: '1px solid #E2E8F0',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        lineHight: "24px",
        height: "98px",
        padding: "12px 16px",
        width: '100%',
        color: "rgba(0, 0, 0, 0.87)",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .generateQueText": {
        color: "#FFFFFF",
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        textTransform: "none"
    },
    "& .cancelQueBtn": {
        height: "58px",
        width: "100%",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px",
        border: "1px solid #94A3B8",
        "&:hover": {
            background: "none"
        }
    },
    "& .generateQueBtn": {
        width: "100%",
        borderRadius: "4px",
        height: "58px",
        padding: "10px 16px 10px 16px",
        border: "1px solid #94A3B8",
        background: "#044352"
    },
    "& textarea::placeholder": {
        color: "#B3B3B3"
    },
    "& input::placeholder": {
        color: "#B3B3B3"
    }
})
// Customizable Area End