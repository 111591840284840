Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.getApiMethodType = "GET";
exports.showUserProfileApiEndPoint = "bx_block_profile/profiles";
exports.errorText = "This field is required";
exports.validEmailErrorMsg = "Please enter a valid email address";
exports.emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.putApiMethodType = "PUT";
exports.postApiMethodType = "POST";
exports.patchApiMethodType = "PATCH";
exports.updateUserProfileApiEndpoint = "bx_block_profile/profiles";
exports.fileErrorText = "You can upload word and pdf files up to 5 mb's.";
exports.errorText = "This field is required";
exports.namelongError = "Name must be min 2 and max 100 char long.";
exports.nameAlphabaticError = "Name must contain only alphabatic char.";

exports.errorDateOfBirthText = "Date of birth should not be less then 18 years";
exports.industryTypeApiEndPoint = "bx_block_dashboardguests/companies/industries_list";
exports.countryApiEndPoint = "bx_block_dashboardguests/companies/countries_list";
exports.regionApiEndPoint = "bx_block_dashboardguests/companies/regions_list";

exports.companySizeApiEndPoint = "bx_block_dashboardguests/companies/size_list";
exports.companyStageApiEndPoint = "bx_block_dashboardguests/companies/stage_list";
exports.jdStyleApiEndPoint = "bx_block_dashboardguests/companies/jd_list";
exports.createCompanyApiEndPoint = "bx_block_dashboardguests/companies";
exports.showCompanyApiEndPoint = "bx_block_dashboardguests/companies/:id";
exports.updateCompanyApiEndPoint = "bx_block_dashboardguests/companies/:id";
exports.userLinksRegEx = /^https:\/\/[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(\/[^\s]*)?$/;
exports.twitterRegex = /^https:\/\/x\.com\/(\w{1,35})$/;
exports.linkedinRegex = /^https:\/\/www\.linkedin\.com\/.*$/
exports.userLinksErrorText = "Please enter a valid HTTPS url.";
exports.linkedInErrorText = "Please enter a valid Linkedin url.";
exports.twitterErrorText = "Please enter a valid Twitter url.";
exports.uploadJdText = "Upload your JD document";
exports.uploadJdSizeText = "You can upload word and pdf files up to 5 MB's";
exports.deleteProfilePhotoApiEndpoint = "bx_block_profile/profiles";
exports.getCountryCodeApiEndPoint = "bx_block_contact_us/fetch_country";
exports.deleteApiMethodType = "DELETE";

exports.getDepartmentApiEndPoint = "account_block/departments";
exports.createUserApiEndPoint = "account_block/accounts/user_onboardings";
exports.updateUserApiEndPoint = "account_block/accounts";
exports.updateUserApiText = "update_onboarded_user";

exports.viewUserApiEndPoint = "account_block/accounts";
exports.userStatusApiEndPoint = "account_block/accounts";
exports.changeStatus = "change_user_status";

exports.listAllUsersApiEndPoint = "account_block/accounts";
exports.deleteUserApiEndPoint = "account_block/accounts";
exports.filterAndSearchApiEndPoint = "account_block/accounts/filter_onbording_users";
exports.getCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies";

// Customizable Area End

