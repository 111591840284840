// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import * as Yup from "yup";
import { ShowCompanyData } from "./Cfchatgptintegration9Controller";
import { i18n } from "i18next";

interface UserProfile {
    error?: string;
    errors: Array<{
        token: string;
    }>,
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            company_name: string;
            "website": string;
            "linkedin": string;
            "twitter": string;
            "country": string;
            "account_id": number;
            "photo": string;
            "full_name": string;
            "date_of_birth": string;
            "email": string;
        }
    }
}


interface SkillAttributes {
    id: number;
    skill_name: string;
}

interface Skill {
    id: string; // Note: id is a string in the JSON
    type: string;
    attributes: SkillAttributes;
}

interface SkillData {
    data: Skill[];
}

interface KeySkillsData {
    data: KeySkill[];
}

interface JobLevel {
    id: number;
    name: string;
}

interface JobFamily {
    id: number;
    name: string;
}

interface JobExperience {
    id: number;
    name: string;
}

interface ChatGPTResponse {
    job_experience: string;
    position: string;
    department: string;
    job_level: string;
    job_summary: string;
    key_skills: string[];
    keywords: string[];
    key_responsibilities: string[];
}

interface JobDescriptionAttributes {
    id: number;
    created_at: string; // ISO 8601 date string
    job_title: string;
    job_experience: JobExperience;
    department: string;
    job_summary: string;
    chatgpt_response: ChatGPTResponse;
    key_words: string[];
    key_skills: KeySkillsData;
    job_level: JobLevel;
    job_family: JobFamily;
}

interface JobDescriptionData {
    id: string; type: string;
    attributes: JobDescriptionAttributes;
}

interface JobDescription {
    data: JobDescriptionData;
}

interface KeySkillAttributes {
    id: number; skill_name: string;
}

interface KeySkill {
    id: string; type: string;
    attributes: KeySkillAttributes;
}

interface KeySkillsData {
    data: KeySkill[];
}

interface JobLevel {
    id: number; name: string;
}

interface JobFamily {
    id: number; name: string;
}

interface JobExperience {
    id: number; name: string;
}

interface ChatGPTResponse {
    job_summary: string;
    job_level: string;
    job_experience: string;
    key_responsibilities: string[];
    position: string;
    department: string;
    key_skills: string[];
    keywords: string[];
}

interface JobDescriptionAttributes {
    department: string;
    job_summary: string;
    id: number;
    created_at: string; // ISO 8601 date string
    job_title: string;
    job_family: JobFamily;
    key_words: string[];
    key_skills: KeySkillsData;
    job_level: JobLevel;
    job_experience: JobExperience;
    chatgpt_response: ChatGPTResponse;
}

interface JobDescriptionData {
    id: string;
    type: string; attributes: JobDescriptionAttributes;
}

interface JobDescriptionFormApiResponse {
    data: JobDescriptionData;
    meta: { message: string; };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    children?: ReactNode;
    selectedMenu?: string;
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    profileId: string;
    errorMsg: string;
    userProfileData: UserProfile;
    sideMenuVisible: boolean;
    isMdUp: boolean;
    languageDropdown: boolean;
    profileDropdown: boolean;
    selectedLanguage: string;
    FormEnableReinitialize: boolean;
    EditJobTitle: string,
    EditDepartmentTeam: string,
    EditJobLevel: number,
    EditJobExperience: number,
    EditJobFamily: number,
    EditJobSummary: string,
    EditKeySkills: Array<number>,
    EditKeyWords: Array<string>,
    jobLevel: { id: number, name: string }[];
    jdstyle: { id: number, name: string }[];
    jobFamily: { id: number, name: string }[];
    jobSummary: string;
    keySkills: { id: number, name: string }[];
    keyWords: { keywords: string }[];
    loader: boolean;
    TitleJobDescription: string;
    FormSubmitButtonText: string;
    active: number;
    progressScreen: boolean;
    progress: number;
    ProgressModel: boolean,
    FormSubmit: boolean,
    jobdescResponse: string;
    SuccessModelTitleText: string;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class JobDescriptionController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    mediaQueryList: MediaQueryList | null = null;
    showProfileApiCallId: string = "";
    ChangeLandApiCallId: string = "";
    CreateJobDescApiCallId: string = "";
    getJobDescLevelApiCallId: string = "";
    getJobDescExpApiCallId: string = "";
    getJobDescKeySkillApiCallId: string = "";
    getJobDescKeyWordApiCallId: string = "";
    getJobDescJobFamilyApiCallId: string = "";
    getJobDescdetailsApiCallId: string = "";
    getJobDescdetailsDeleteApiCallId: string = "";
    getCompanyApiCallId: string = "";
    getSearchJobApiCallId: string = "";
    getCalendarJobApiCallId: string = "";
    ExportAllApiCallId: string = "";
    EditJobDescApiCallId: string = "";
    GetJobDescrptionDataApiCallId: string = "";

    interval: number | undefined;
    intervals: number | undefined;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            profileId: "",
            errorMsg: "",
            userProfileData: {} as UserProfile,
            sideMenuVisible: false,
            isMdUp: false,
            languageDropdown: false,
            profileDropdown: false,
            selectedLanguage: "English",
            EditJobTitle: "",
            EditDepartmentTeam: "",
            FormEnableReinitialize: false,
            EditJobLevel: -1,
            EditJobExperience: -1,
            EditJobFamily: -1,
            EditJobSummary: "",
            EditKeySkills: [],
            EditKeyWords: [],
            jobLevel: [],
            jdstyle: [],
            jobFamily: [],
            keySkills: [],
            keyWords: [],
            jobSummary: "",
            loader: false,
            TitleJobDescription: "",
            FormSubmitButtonText: "",
            active: 0,
            progressScreen: false,
            progress: 0,
            ProgressModel: false,
            FormSubmit: false,
            jobdescResponse: "",
            SuccessModelTitleText: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            // Get the data for the response
            this.SetJobDescriptionApiResponse(apiRequestCallId, responseJson);

            this.companyApiResponse(apiRequestCallId, responseJson);
            this.KeyWordApiCallResponseSet(apiRequestCallId, responseJson);
            this.JobFamilyGetApiCallResponseSet(apiRequestCallId, responseJson);
            this.JobExprinceApiCallResponseSet(apiRequestCallId, responseJson);
            this.JobLevelApiCall(apiRequestCallId, responseJson);
            this.KeySkillsApiCallResponseSet(apiRequestCallId, responseJson);
            this.FormApiCallSetResponse(apiRequestCallId, responseJson);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    KeyWordApiCallResponseSet = (ApiCallId: string, response: {
        keywords: string[];
    }) => {
        if (ApiCallId === this.getJobDescKeyWordApiCallId) {
            const keyWordsData = response?.keywords?.map((data: any) => ({
                keywords: data,
            }));
            this.setState({ keyWords: keyWordsData });
        }
    }

    JobFamilyGetApiCallResponseSet = (apiRequestCallId: string, responseJson: Array<{
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
    }>) => {
        if (apiRequestCallId === this.getJobDescJobFamilyApiCallId) {
            this.setState({ jobFamily: responseJson })
        }
    }

    JobLevelApiCall = (apiRequestCallId: string, responseJson: Array<{
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
    }>) => {
        if (apiRequestCallId === this.getJobDescLevelApiCallId) {
            this.setState({ jobLevel: responseJson })
        }
    }

    JobExprinceApiCallResponseSet = (apiRequestCallId: string, responseJson: Array<{
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
    }>) => {
        if (apiRequestCallId === this.getJobDescExpApiCallId) {
            this.setState({ jdstyle: responseJson })
        }
    }

    KeySkillsApiCallResponseSet = (ApiCallId: string, response: SkillData) => {
        if (ApiCallId === this.getJobDescKeySkillApiCallId) {
            const keySkillData = response.data.map((data) => ({
                id: data.attributes.id,
                name: data.attributes.skill_name,
            }));
            this.setState({ keySkills: keySkillData });
        }
    }

    async componentDidMount() {
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        })
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        this.GetCompanyApiCall();
        this.jobDescriptionGetCall()
        this.jobDescriptionExpGetCall()
        this.jobDescriptionKeySkillGetCall()
        this.jobDescriptionKeyWordGetCall()
        this.jobDescriptionJobFamilyGetCall()

        this.GetJobDescriptionDataApiCallFuncation()
    }

    NavigateToAnyPage = (pageName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        }else{
            return key
        }
    }

    EditJobSchema = () => {
        return Yup.object({
            jobTitle: Yup.string().required(this.TraslationFuncation("RequiredField")),
            DepartmentTeam: Yup.string().required(this.TraslationFuncation("RequiredField")),
            jobLevel: Yup.string().required(this.TraslationFuncation("RequiredField")),
            JobExperience: Yup.string().required(this.TraslationFuncation("RequiredField")),
            jobFamily: Yup.string().required(this.TraslationFuncation("RequiredField")),
            jobSummary: Yup.string().required(this.TraslationFuncation("RequiredField")),
            keySkills: Yup.string().required(this.TraslationFuncation("RequiredField")),
            keyWords: Yup.string().required(this.TraslationFuncation("RequiredField"))
        });
    }

    ConditionForLanguage = (TrueCondition: string, FalseCondition: string) => {
        if (this.state.selectedLanguage.toLowerCase() === "english") {
            return TrueCondition
        } else {
            return FalseCondition
        }
    }

    renderjobLevel = (selected: number) => {
        const selectedjobLevel = this.state.jobLevel?.find((option) => option.id === selected);
        return selectedjobLevel ? selectedjobLevel.name : (
            <div className="dropDownStyleplaceHolder"
            >  {this.TraslationFuncation("JobDescriptionPage.SpecifyYourTitle")}
            </div>
        );
    }

    handleCondition = (condition: boolean, truePart: React.ReactNode, falsePart: React.ReactNode) => {
        return condition ? truePart : falsePart
    }

    GetCompanyApiCall = () => {
        const headers = {
            "token": this.state.token
        };

        const getMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCompanyApiCallId = getMsg.messageId;

        getMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.showCompanyApiEndPoint}`
        );
        
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(getMsg.id, getMsg);
    }
    jobDescriptionExpGetCall = () => {

        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessageExp = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getJobDescExpApiCallId = requestMessageExp.messageId;
        requestMessageExp.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getJobDescExpApiEndPoint}`
        );
        requestMessageExp.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessageExp.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessageExp.id, requestMessageExp);
    }

    jobDescriptionGetCall = () => {

        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessageTwo = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getJobDescLevelApiCallId = requestMessageTwo.messageId;
        requestMessageTwo.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getJobDescLevelApiEndPoint
        );
        requestMessageTwo.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessageTwo.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessageTwo.id, requestMessageTwo);
    }

    jobDescriptionKeyWordGetCall = () => {

        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessageKeyWords = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getJobDescKeyWordApiCallId = requestMessageKeyWords.messageId;
        requestMessageKeyWords.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getJobDescKeyWordApiEndPoint}`
        );
        requestMessageKeyWords.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessageKeyWords.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessageKeyWords.id, requestMessageKeyWords);
    }
    jobDescriptionKeySkillGetCall = () => {

        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessageKeySklis = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getJobDescKeySkillApiCallId = requestMessageKeySklis.messageId;
        requestMessageKeySklis.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getJobDescKeySkillApiEndPoint}`
        );
        requestMessageKeySklis.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessageKeySklis.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessageKeySklis.id, requestMessageKeySklis);
    }

    jobDescriptionJobFamilyGetCall = () => {

        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessageJobFamily = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getJobDescJobFamilyApiCallId = requestMessageJobFamily.messageId;
        requestMessageJobFamily.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getJobDescJobFamilyAPiEndPoint}`
        );
        requestMessageJobFamily.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessageJobFamily.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessageJobFamily.id, requestMessageJobFamily);
    }

    companyApiResponse = (ApiCallId: string, responseJson: ShowCompanyData) => {
        if (ApiCallId === this.getCompanyApiCallId) {
            if (responseJson.message === "No associated company found for the current user") {
                this.NavigateToAnyPage("cfchatgptintegration9")
            }
        }
    }

    GetJobDescriptionDataApiCallFuncation = async () => {
        const ViewJobId = await getStorageData('ViewJobId')
        if (ViewJobId) {
            this.setState({
                loader: true,
                TitleJobDescription: this.TraslationFuncation("JobDescriptionPage.EditJobDescriptionSubText"),
                FormSubmitButtonText: this.TraslationFuncation("JobDescriptionPage.UpdateJobDescription"),
            })
            this.GetJobDescrptionApiCallFuncation(ViewJobId)
        } else {
            this.setState({
                TitleJobDescription: this.TraslationFuncation("JobDescriptionPage.JobDescription"),
                FormSubmitButtonText: this.TraslationFuncation("JobDescriptionPage.GenerateJobDescription"),
            })
        }
    }

    GetJobDescrptionApiCallFuncation = (ID:string) =>{

        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetJobDescrptionDataApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getJobDescDetailsAPiEndPoint}/${ID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    BackToJobDescription = () => {
        this.NavigateToAnyPage("Cfchatgptintegration9")
        removeStorageData("ViewJobId");
    }

    SetJobDescriptionApiResponse = (ApiCallId: string, responseJson: JobDescription) => {
        if (ApiCallId === this.GetJobDescrptionDataApiCallId) {
            this.setState({
                EditJobTitle: responseJson.data.attributes.job_title,
                EditDepartmentTeam: responseJson.data.attributes.department,
                EditJobLevel: Number(responseJson.data.attributes.job_level.id),
                EditJobExperience: Number(responseJson.data.attributes.job_experience.id),
                EditJobFamily: Number(responseJson.data.attributes.job_family.id),
                EditJobSummary: responseJson.data.attributes.job_summary,
                EditKeySkills: responseJson.data.attributes.key_skills.data.map((item) => item.attributes.id),
                EditKeyWords: responseJson.data.attributes.key_words,
                loader: false
            })
            this.FormEnableReinitializeFuncation();
        }
    }

    FormEnableReinitializeFuncation = () => {
        this.setState({
            FormEnableReinitialize: true,
        }, () => {
            this.setState({
                FormEnableReinitialize: false,
            })
        })
    }

    JobDescripationFormApiCallFuncation = async (data: {
        DepartmentTeam: string, JobExperience: number, jobFamily: number, jobLevel: number, jobSummary: string
        , jobTitle: string, keySkills: Array<number>, keyWords: Array<string>
    }) => {
        const ViewJobId = await getStorageData('ViewJobId')
        const httpBody = {
            job_description: {
                job_title: data.jobTitle,
                department: data.DepartmentTeam,
                job_level_id: data.jobLevel,
                job_family_id: data.jobFamily,
                job_experience_id: data.JobExperience,
                job_summary: data.jobSummary,
                key_skills: data.keySkills,
                key_words: data.keyWords,
                key_skill_ids: data.keySkills,
            }
        }
        this.setState({
            FormSubmit: true,
            progressScreen: true,
        })

        if (ViewJobId) {
            this.EditJObDesFormApiCallFun(ViewJobId,httpBody)
        } else {
            this.CreateJobDesFormApiCallFun(httpBody)
        }
    }

    EditJObDesFormApiCallFun= (ID:string,httpBody:{
        job_description: {
            job_title: string;
            department: string;
            job_level_id: number;
            job_family_id: number;
            job_experience_id: number;
            job_summary: string;
            key_skills: number[];
            key_words: string[];
            key_skill_ids: number[];
        };
    }) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.EditJobDescApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createJobDescApiEndPoint}/${ID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PUT
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    CreateJobDesFormApiCallFun= (httpBody:{
        job_description: {
            job_title: string;
            department: string;
            job_level_id: number;
            job_family_id: number;
            job_experience_id: number;
            job_summary: string;
            key_skills: number[];
            key_words: string[];
            key_skill_ids: number[];
        };
    }) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.CreateJobDescApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createJobDescApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    FormApiCallSetResponse = (ApiCallId: string, responseJson: JobDescriptionFormApiResponse) => {
        if (ApiCallId === this.EditJobDescApiCallId) {
            this.setState({
                ProgressModel: true,
                SuccessModelTitleText: this.TraslationFuncation("JobDescriptionPage.JobDescriptionUpdate"),
                progress: 100
            })
        }
        if (ApiCallId === this.CreateJobDescApiCallId) {
            this.setState({
                ProgressModel: true,
                progress: 100,
                SuccessModelTitleText: this.TraslationFuncation("JobDescriptionPage.JobDescriptionCreated")
            })
        }
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if (prevState.progressScreen !== this.state.progressScreen) {
            if (this.state.progressScreen) {
                this.interval = setInterval(() => {
                    if (this.state.active === 6) {
                        this.setState({ active: 0 })
                    } else
                        this.setState({ active: this.state.active + 1 })
                }, 300) as unknown as number


                this.intervals = setInterval(() => {
                    if (this.state.progress >= 80) {
                        clearInterval(this.interval);
                        clearInterval(this.intervals);
                        this.setState({ progress: 100 });
                        this.setState({ ProgressModel: true });
                    }
                    this.setState({ progress: this.state.progress + 1 })
                }, 100) as unknown as number
            }
        }
    }

    async componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
        }
        if (this.intervals) {
            clearInterval(this.intervals);
            this.intervals = undefined;
        }
    }

    CloseSuccessModel = () => {
        removeStorageData("ViewJobId");
        this.NavigateToAnyPage("Cfchatgptintegration9")
        this.setState({
            ProgressModel: false,
            progress:0,
            loader: true,
        })
    }

    ViewJobDescription = () =>{
        this.NavigateToAnyPage("ViewJobDescription")
    }

    // Customizable Area End

}