import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    Divider,
    Select,
    InputBase,
    MenuItem,
    Button,
    Modal,
    CircularProgress,
    LinearProgress,
    SelectChangeEvent
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { Formik, Form, ErrorMessage } from "formik";
import { arrow_Left, progress, right } from "./assets";
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { withTranslation } from "react-i18next";
import { MainWrapper, Wrapper } from "./Cfchatgptintegration9.web"
import JobDescriptionController, {
    Props,
} from "./JobDescriptionController.web";
// Customizable Area End

export default class JobDescription extends JobDescriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderjd = (selected: number) => {
        const selectedjdStyle = this.state.jdstyle?.
            find((option) => option.id === selected);
        return selectedjdStyle ? selectedjdStyle.name :
            (<div className="dropDownStyleplaceHolder">
                {this.TraslationFuncation("JobDescriptionPage.SelectYourJobExperience")}</div>
            )
    }

    renderjobfamily = (selected: number) => {
        const selectedfamily = this.state.jobFamily?.
            find((option) => option.id === selected);
        return selectedfamily ? selectedfamily.name : (
            <div
                className="dropDownStyleplaceHolder">
                {this.TraslationFuncation("JobDescriptionPage.SpecifyYourJobFamily")}
            </div>
        );
    }

    loaderScreen = () => {
        return (
            <>
                <Box className="loader-outer"
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <Box className="loader-inner">
                        <CircularProgress />
                    </Box>
                </Box>
            </>
        )
    }

    SuccessModelForForm = () => {
        return (
            <Modal
                open={this.state.ProgressModel}
                data-test-id="successModel"
                onClose={this.CloseSuccessModel}>
                <CustomPopup
                    data-test-id="successModelCustomPopup"
                    headingPopup={this.state.SuccessModelTitleText}
                    titlePopup={this.state.jobdescResponse}
                    subTitlePopup={this.TraslationFuncation("JobDescriptionPage.JobDescriptionHasSavedInTheSystem")}
                    buttonText={this.TraslationFuncation("View")}
                    showCancelButton={false}
                    successIcon={right.default}
                    onOpen={() => { this.ViewJobDescription() }}
                    onClose={this.CloseSuccessModel}
                />
            </Modal>
        )
    }

    progressDataScreen = () => {
        return (
            <Grid container justifyContent={"center"} style={{ direction: "ltr" }} >
                <Grid item xs={11}>
                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
                            <Box data-test-id="BackToJobDescription" onClick={this.BackToJobDescription}
                                style={{
                                    width: "47px", height: "47px", border: "1px solid #ECECEC", borderRadius: "50%",
                                    display: "flex", alignItems: "center", justifyContent: "center"
                                }}
                            >
                                <img src={arrow_Left.default}
                                    alt="Back Arrow"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                            <Typography className="jobTitle">{this.TraslationFuncation("JobDescriptionPage.GeneratingJobDescription")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={"jobMainHeaderGrid"}>
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                            <Wrapper>
                                <Box className="progressMainContainer">
                                    <Box className="progressContainer">
                                        <Box className="progressImage">
                                            <img src={progress.default} />
                                        </Box>
                                        <Box className="dotsProgress">
                                            {[0, 1, 2, 3, 4, 5, 6].map((obj, index) => <Box key={index} style={{
                                                width: "7.5px", height: "7.5px",
                                                borderRadius: "50%", backgroundColor: "#059669",
                                                transform: (index === this.state.active, "scale(1.5)", "scale(1)")
                                            }}></Box>)}
                                        </Box>
                                        <Box className="linearProgressBar">
                                            <LinearProgress
                                                className="linearProgress"
                                                variant="determinate"
                                                value={this.state.progress}
                                            />
                                        </Box>
                                        <Box className="ProgressTextContain">
                                            <Typography className="progressTextRunning">
                                                {this.state.progress >= 100 ? 100 : this.state.progress}{this.TraslationFuncation("JobDescriptionPage.OnePercentCompleteFinalisingJobDescription")}
                                            </Typography>
                                        </Box>
                                        <Box className="ProgressCreationContent">
                                            <Typography className="progressContent1">
                                                {this.TraslationFuncation("JobDescriptionPage.HangTightJobDescriptionCreationInProgress")}
                                            </Typography>
                                            <Typography className="progressContent2">
                                                {this.TraslationFuncation("JobDescriptionPage.WaitWeGenerateDescription")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Wrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    RenderJobTitle = (handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        values: {
            jobTitle: string
        }
    ) => {
        return (<Grid item xs={12}>
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.TraslationFuncation("JobDescriptionPage.JobTitle")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="jobTitle" component="div" className="errorStyle" />
                </Box>
                <input
                    className="dropDownStyle"
                    placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourTitle")}
                    data-test-id="EditJobTitle"
                    name="jobTitle"
                    onChange={(e) => {
                        handleChange(e)
                        this.setState({
                            EditJobTitle: e.target.value
                        })
                    }}
                    onBlur={handleBlur}
                    value={values.jobTitle}
                />
            </Wrapper>
        </Grid>
        )
    }

    RenderDepartmentTeam = (
        handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        values: {
            DepartmentTeam: string
        }
    ) => {
        return (
            <Grid item xs={12} md={6}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.DepartmentTeam")}  <span>*</span>
                        </Typography>
                        <ErrorMessage name="DepartmentTeam" component="div" className="errorStyle" />
                    </Box>
                    <input
                        className="dropDownStyle"
                        placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourDepartmentTeam")}
                        name="DepartmentTeam"
                        onChange={(e) => {
                            handleChange(e)
                            this.setState({
                                EditDepartmentTeam: e.target.value
                            })
                        }}
                        data-test-id="EditDepartmentTeam"
                        onBlur={handleBlur}
                        value={values.DepartmentTeam}
                    />
                </Wrapper>
            </Grid>
        )
    }

    RenderJobSummary = (
        handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        values: {
            jobSummary: string
        }
    ) => {
        return (
            <Grid item xs={12}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.JobSummary")} <span>*</span>
                        </Typography>
                        <ErrorMessage
                            name="jobSummary"
                            component="div"
                            className="errorStyle" />
                    </Box>
                    <textarea
                        className={`cultureInputField`}
                        placeholder={this.TraslationFuncation("JobDescriptionPage.WriteAboutYourJob")}
                        data-test-id="EditsetJobSummary"
                        name="jobSummary"
                        value={values.jobSummary}
                        onChange={(e) => {
                            handleChange(e)
                            this.setState({
                                EditJobSummary: e.target.value
                            })
                        }}
                        onBlur={handleBlur}
                    />
                    <Typography className="companyCultureLimit">{this.state.jobSummary.length}/1000</Typography>
                </Wrapper>
            </Grid>
        )
    }

    RenderJoblevelTextFiled = (
        handleChange: (e: SelectChangeEvent<number>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        ValuesJobLevel: number
    ) => {
        return (
            <Grid item xs={12} md={6}>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.TraslationFuncation("JobDescriptionPage.JobLevel")}  <span>*</span>
                    </Typography>
                    <ErrorMessage name="jobLevel" component="div" className="errorStyle" />
                </Box>
                <Select
                    data-test-id="EditsetJobLevel"
                    className={`dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    name="jobLevel"
                    value={ValuesJobLevel}
                    onChange={(e) => {
                        handleChange(e)
                        this.setState({
                            EditJobLevel: Number(e.target.value)
                        })
                    }}
                    onBlur={handleBlur}
                    displayEmpty
                    renderValue={(selected) => {
                        return this.renderjobLevel(Number(selected))
                    }}
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.jobLevel.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        )
    }

    RenderJobExperience = (
        handleChange: (e: SelectChangeEvent<number>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        ValuesJobExperience: number
    ) => {
        return (
            <Grid item xs={12} md={6}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.JobExperience")} <span>*</span>
                        </Typography>
                        <ErrorMessage name="JobExperience" component="div" className="errorStyle" />
                    </Box>
                    <Select
                        data-test-id="EditsetJdStyle"
                        className={`dropDownStyle`}
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        variant="outlined"
                        name="JobExperience"
                        value={ValuesJobExperience}
                        onChange={(e) => {
                            handleChange(e)
                            this.setState({
                                EditJobExperience: Number(e.target.value)
                            })
                        }}
                        onBlur={handleBlur}
                        displayEmpty
                        renderValue={(selected) =>
                            this.renderjd(Number(selected))}
                        IconComponent={KeyboardArrowRightIcon}
                        input={<InputBase />}
                    >
                        {this.state.jdstyle?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Wrapper>
            </Grid>
        )
    }

    RenderJobFamilyTextfiled = (
        handleChange: (e: SelectChangeEvent<number>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        ValuesJobFamily: number
    ) => {
        return (
            <Grid item xs={12} md={6}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.JobFamily")} <span>*</span>
                        </Typography>
                        <ErrorMessage name="jobFamily" component="div" className="errorStyle" />
                    </Box>
                    <Select
                        data-test-id="EditsetJobFamily"
                        className={`dropDownStyle`}
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        variant="outlined"
                        name="jobFamily"
                        value={ValuesJobFamily}
                        onChange={(e) => {
                            handleChange(e)
                            this.setState({
                                EditJobFamily: Number(e.target.value)
                            })
                        }}
                        onBlur={handleBlur}
                        displayEmpty
                        renderValue={
                            (selected) => this.renderjobfamily(Number(selected))}
                        IconComponent={KeyboardArrowRightIcon}
                        input={<InputBase />}
                    >
                        {this.state.jobFamily?.map((option) => (
                            <MenuItem key={option.id} value={`${option.id}`}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Wrapper>
            </Grid>
        )
    }

    RenderKeySkills = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        ValueskeySkills: number[],
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Grid item xs={12} md={6}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.KeySkills")}  <span>*</span>
                        </Typography>
                        <ErrorMessage name="keySkills"
                            component="div"
                            className="errorStyle" />
                    </Box>
                    <Select
                        className="dropDownStyle"
                        data-test-id="setkeySkills"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        value={ValueskeySkills}
                        name="keySkills"
                        variant="outlined"
                        onChange={(event) => {
                            const value = event.target.value;
                            setFieldValue("keySkills", typeof value === 'string' ? value.split(',') : value);
                        }}
                        onBlur={handleBlur}
                        multiple
                        displayEmpty
                        renderValue={(selected) =>
                            this.handleCondition(selected?.length > 0, selected?.map((id: string | number) => {
                                const skill = this.state.keySkills.find(skill => skill.id === id);
                                return skill ? skill.name : "";
                            }).join(" | "), <div className="dropDownStyleplaceHolder">{
                                this.TraslationFuncation("JobDescriptionPage.SelectYourKeySkills")
                            }</div>)
                        }
                        input={<InputBase />}
                        IconComponent={KeyboardArrowRightIcon}
                    >
                        {this.state.keySkills?.map((option: { id: number, name: string }) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Wrapper>
            </Grid>
        )
    }

    RenderKeywords = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        ValueskeyWords: string[],
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Grid item xs={12} md={6}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.KeyWords")}  <span>*</span>
                        </Typography>
                        <ErrorMessage name="keyWords"
                            component="div" className="errorStyle" />
                    </Box>
                    <Select
                        value={ValueskeyWords}
                        data-test-id="setkeyWords"
                        className="dropDownStyle"
                        name="keyWords"
                        variant="outlined"
                        onChange={(event) => {
                            const value = event.target.value;
                            setFieldValue("keyWords", typeof value === 'string' ? value.split(',') : value);
                        }}
                        multiple
                        displayEmpty
                        onBlur={handleBlur}
                        renderValue={(selected) => {
                            if (selected?.length > 0) {
                                return selected.map((obj: string) => {
                                    const wordObj = this.state.keyWords.find((words) => words.keywords === obj);
                                    return wordObj ? wordObj.keywords : "";
                                })
                                    .join(" | ");
                            } else {
                                return (
                                    <div className="dropDownStyleplaceHolder">
                                        {this.TraslationFuncation("JobDescriptionPage.SelectYourKeyWords")}
                                    </div>
                                );
                            }
                        }}
                        IconComponent={KeyboardArrowRightIcon}
                        input={<InputBase />}
                    >
                        {this.state.keyWords?.map((option, index) => (
                            <MenuItem key={index}
                                value={option.keywords}>
                                {option.keywords}
                            </MenuItem>
                        ))}
                    </Select>
                </Wrapper>
            </Grid>
        )
    }

    FormAndLoader = () => {
        return this.state.loader ? this.loaderScreen() :
            <Grid container justifyContent={"center"} style={{ direction: "ltr" }} >
                <Grid item xs={11}>
                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
                            <Box data-test-id="BackToJobDescription"
                                style={{
                                    width: "47px", height: "47px", border: "1px solid #ECECEC", borderRadius: "50%",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    cursor: 'pointer',
                                }}
                                onClick={this.BackToJobDescription}
                            >
                                <img src={arrow_Left.default}
                                    alt="Back Arrow"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography className="jobTitle">{this.state.TitleJobDescription}</Typography>
                        </Grid>
                        <Formik
                            data-test-id="EditJobFormik"
                            initialValues={{
                                jobTitle: this.state.EditJobTitle,
                                DepartmentTeam: this.state.EditDepartmentTeam,
                                jobLevel: this.state.EditJobLevel,
                                JobExperience: this.state.EditJobExperience,
                                jobFamily: this.state.EditJobFamily,
                                jobSummary: this.state.EditJobSummary,
                                keySkills: this.state.EditKeySkills,
                                keyWords: this.state.EditKeyWords,
                            }}
                            validationSchema={this.EditJobSchema}
                            enableReinitialize={this.state.FormEnableReinitialize}
                            onSubmit={(values) => {
                                this.JobDescripationFormApiCallFuncation(values);
                            }}
                        >
                            {({
                                handleSubmit,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        noValidate
                                        name="EditJobDetailForm"
                                        autoComplete="off"
                                    >
                                        <Grid item xs={12} sm={12} md={11.2} lg={11.5} className="jobDetailHeaderGrid">
                                            <Wrapper>
                                                <Box className="companyItemMainBox">
                                                    <Grid container className={"companyCardContainer" + " " + this.ConditionForLanguage("", "companyCardContainerArabic")} justifyContent={"flex-start"}>
                                                        <Grid item xs={12} className="companyCardItem">
                                                            <Card className="companyCardDescBox">
                                                                <CardContent className="cardContentPadding">
                                                                    <Grid container>
                                                                        <Grid item xs={12} className="orgDetailPadding">
                                                                            <Typography className="JobDetailTitle">
                                                                                {this.TraslationFuncation("JobDescriptionPage.JobDescription")}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Divider
                                                                                sx={{
                                                                                    color: "#E2E8F0",
                                                                                    width: 'calc(100% - 35px)',
                                                                                    marginRight: '35px',
                                                                                }}
                                                                            />

                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={3.5} className="contentPadding">
                                                                        {this.RenderJobTitle(handleChange, handleBlur, values)}
                                                                        {this.RenderDepartmentTeam(handleChange, handleBlur, values)}
                                                                        {this.RenderJoblevelTextFiled(handleChange, handleBlur, values.jobLevel)}
                                                                        {this.RenderJobExperience(handleChange, handleBlur, values.JobExperience)}
                                                                        {this.RenderJobFamilyTextfiled(handleChange, handleBlur, values.jobFamily)}
                                                                        {this.RenderJobSummary(handleChange, handleBlur, values)}
                                                                        {this.RenderKeySkills(handleBlur, values.keySkills, setFieldValue)}
                                                                        {this.RenderKeywords(handleBlur, values.keyWords,setFieldValue)}

                                                                        <Grid item xs={12}>
                                                                            <Wrapper>
                                                                                <Grid container>
                                                                                    <Grid item xs={12}>
                                                                                        <Button className="sumbmitBtn" data-test-id="editJobDescription" onClick={() => {
                                                                                            handleSubmit()
                                                                                        }}
                                                                                        >
                                                                                            <Typography className="sumbmitText">
                                                                                                {this.state.FormSubmitButtonText}
                                                                                            </Typography>
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Wrapper>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Wrapper>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
    }

    RenderAllScreens = () => {
        if (this.state.FormSubmit === false) {
            return this.FormAndLoader()
        } else if (this.state.FormSubmit && this.state.progressScreen) {
            return this.progressDataScreen()
        }
    }


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Job descriptions" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }} >
                    {this.RenderAllScreens()}
                    {this.SuccessModelForForm()}
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

export const JobDescriptionWeb = withTranslation()(JobDescription)

// Customizable Area Start