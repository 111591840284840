import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";

interface ViewCompanyDataResponse {
    company_size_name?: string;
    company_culture?: string;
    industry_name?: string;
    organizational_keywords?: string;
    job_description_style_name?: string;
    document_url?: string;
    document_filename?: string;
    document_size?: string;
    country?: string;
    region?: string;
    key?: string;
    company_name?: string;
    stage_name?: string;
}

interface ViewCompanyData {
    message?: string;
    error?: string;
    organisation_details?: {
        data?: {
            id: string;
            type: string;
            attributes: ViewCompanyDataResponse
        }
    }
}

interface CountryNameAndRegion {
    key: string;
    country_name: string;
    country_code: string;
    region: string;
}

interface ContryResponse {
    message?: string;
    error?: string;
    data?: CountryNameAndRegion[];
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    errorMsg: string;
    companyId: string | undefined;
    showCompanyData: ViewCompanyDataResponse | undefined;
    loader: boolean;
    token: string;
    selectedLanguage: string;
    countryData: ContryResponse;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewCompanyDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    showCompanyApiCallId: string = "";
    countryRegionApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            errorMsg: "",
            companyId: "",
            showCompanyData: {},
            loader: false,
            selectedLanguage: "",
            countryData: {}
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.showCompanyApiCallId) {
                this.handleShowCompanyApiResponse(responseJson)
            }
            if (apiRequestCallId === this.countryRegionApiCallId) {
                this.countryRegionApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
        })
        this.getCountryRegionApiCall();
    }

    handleShowCompanyApiResponse = (responseJson: ViewCompanyData) => {
        if (responseJson && !responseJson.error) {
            let companyData = responseJson.organisation_details?.data?.attributes;

            if (this.state.countryData && companyData) {
                const country = this.state.countryData.data?.filter((item) => {
                    return item.key === companyData?.key
                }) || [];

                companyData['country'] = country[0]?.country_name || companyData['country'];
                companyData['region'] = country[0]?.region || companyData['region'];
            }
            this.setState({
                companyId: responseJson.organisation_details?.data?.id,
                showCompanyData: companyData, 
                loader: false
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }

    countryRegionApiResponse = (responseJson: ContryResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ countryData: responseJson }, () => {
                this.viewCompanyApiCall();
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    viewCompanyApiCall = () => {
        this.setState({ loader: true })
        const headers = {
            "Content-Type": "application/json",
            "token": this.state.token
        };
        const getViewCompanyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showCompanyApiCallId = getViewCompanyMsg.messageId;
        getViewCompanyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getViewCompanyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.showCompanyApiEndPoint}`
        );
        getViewCompanyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getViewCompanyMsg.id, getViewCompanyMsg);
    }

    getCountryRegionApiCall = () => {
        this.setState({ loader: true })
        let lang = this.state.selectedLanguage === "English" ?
            this.state.selectedLanguage.toLowerCase() : "ar"

        const headers = {
            "Content-Type": "application/json",
        };
        const getMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.countryRegionApiCallId = getMsg.messageId;
        getMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCountryCodeApiEndPoint}?language=${lang}`
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getMsg.id, getMsg);
    }

    navigateToCompanyDetail = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompanyDetails");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    navigateToBack = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End

}