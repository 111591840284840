import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Input,
    Card,
    CardContent,
    Chip,
    Divider,
    Select,
    InputBase,
    MenuItem,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { backArrow, uploadImage, more, uploadedFile, successImg } from "./assets";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
// Customizable Area End

import CompanyDetailsController, {
    Props
} from "./CompanyDetailsController";

export default class CompanyDetails extends CompanyDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    organizationName = () => {
        const error = this.state.submitClicked &&
            !this.state.organisationName &&
            this.props.i18n?.t("companyDetails.errorText").toString()
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.organisationName").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Input
                    data-test-id="organisation_name"
                    className={`${error ? "errorClass" : ""} userEnterInputField`}
                    disableUnderline
                    placeholder={this.props.i18n?.t("companyDetails.orgNamePlaceholder").toString()}
                    value={this.state.organisationName}
                    disabled={this.state.isEdit}
                    onChange={this.organisationChange}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    countryList = () => {
        const error = this.state.submitClicked &&
            !this.state.selectedCountry &&
            this.props.i18n?.t("companyDetails.errorText").toString() || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.country").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="country"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedCountry}
                    renderValue={
                        this.state.selectedCountry
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    {this.props.i18n?.t("companyDetails.countryPlaceholder").toString()}
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    onChange={this.handleCountryChange}
                    input={<InputBase />}
                >
                    {this.state.country.data?.map((country) => (
                        <MenuItem key={country.key} value={country.country_name}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {country.country_name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    regionList = () => {
        const error = this.state.submitClicked &&
            !this.state.selectedRegion &&
            this.props.i18n?.t("companyDetails.errorText").toString() || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.region").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Input
                    data-test-id="region"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    disableUnderline
                    placeholder={this.props.i18n?.t("companyDetails.regionPlaceholder").toString()}
                    value={this.state.selectedRegion}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    companyCulture = () => {
        const error = this.state.submitClicked &&
            !this.state.companyCulture &&
            this.props.i18n?.t("companyDetails.errorText").toString() || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.orgCulture").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <textarea
                    data-test-id="company_culture"
                    className={`${error ? "errorClass" : ""} cultureInputField`}
                    placeholder={this.props.i18n?.t("companyDetails.culturePlaceholder").toString()}
                    value={this.state.companyCulture}
                    onChange={(event) => this.handleCopanyCulture(event)}
                />
                <Typography className="companyCultureLimit">{this.state.companyCulture?.length || 0}/1000</Typography>
            </Wrapper>
        )
    }

    industryType = () => {
        const error = this.state.submitClicked &&
            !this.state.selectedIndustryType &&
            this.props.i18n?.t("companyDetails.errorText").toString() || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.industryType").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="industry_type"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedIndustryType}
                    renderValue={
                        this.state.selectedIndustryType
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    {this.props.i18n?.t("companyDetails.industryPlaceholder").toString()}
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    onChange={this.handleIndustryType}
                    input={<InputBase />}
                >
                    {this.state.industryType.industries.map((option) => (
                        <MenuItem key={option.id} value={option.id}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    companySize = () => {
        const error = this.state.submitClicked &&
            !this.state.selectedCompanySize &&
            this.props.i18n?.t("companyDetails.errorText").toString() || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.orgSize").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="company_size"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedCompanySize}
                    onChange={this.handleCompanySize}
                    displayEmpty
                    renderValue={
                        this.state.selectedCompanySize
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    {this.props.i18n?.t("companyDetails.sizePlaceholder").toString()}
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.companySize.sizes.map((option) => (
                        <MenuItem key={option.id} value={option.id}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    companyStage = () => {
        const error = this.state.submitClicked &&
            !this.state.selectedCompanyStage &&
            this.props.i18n?.t("companyDetails.errorText").toString() || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.orgStage").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="company_stage"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedCompanyStage}
                    onChange={this.handleCompanyStage}
                    displayEmpty
                    renderValue={
                        this.state.selectedCompanyStage
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    {this.props.i18n?.t("companyDetails.stagePlaceholder").toString()}
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.companyStage.stages.map((option) => (
                        <MenuItem key={option.id} value={option.id}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    jdStyle = () => {
        const error = this.state.submitClicked &&
            !this.state.selectedJdStyle &&
            this.props.i18n?.t("companyDetails.errorText").toString() || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.jdStyle").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="jd_style"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedJdStyle}
                    onChange={this.handleJdStyle}
                    displayEmpty
                    renderValue={
                        this.state.selectedJdStyle
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    {this.props.i18n?.t("companyDetails.jdPlaceholder").toString()}
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.jdStyle.job_styles.map((option) => (
                        <MenuItem key={option.id} value={option.id}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    organizationKeywords = () => {
        const error = this.state.submitClicked &&
            (this.state.chipData || []).length < 1 &&
            this.props.i18n?.t("companyDetails.errorText").toString() || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.orgKeyword").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Box className={`${error ? "errorClass" : ""} organizationKeywords`}>
                    <Input
                        className="orgKeywordInput"
                        fullWidth
                        data-test-id="organization_keywords"
                        placeholder={this.props.i18n?.t("companyDetails.keywordPlaceholder").toString()}
                        value={this.state.organizationKeywords}
                        onChange={this.handleKeywordChange}
                        onKeyDown={this.handleAddChip}
                        sx={{
                            '&:before': {
                                borderBottom: 'none',
                            },
                            '&:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none',
                            },
                            '&:after': {
                                borderBottom: 'none',
                            },
                        }}
                    />
                    <Box className="chipContainer">
                        {this.state.chipData?.map((data, index) => {
                            return (
                                <Chip
                                    className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "chip" : "chipArabic"}`}
                                    key={index}
                                    label={data}
                                    data-test-id="organization_keywords_chip"
                                    onDelete={this.handleDeleteChip(data)}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Wrapper>
        )

    }
    jdImageGrid = () => {
        return (
            <Grid item xs={12} sm={2} md={1.5} className="jdInnerGrid">
                <img
                    src={(this.state.selectedFile || (this.state.isEdit && this.state.uploadedFile)) ? uploadedFile.default : uploadImage.default}
                    alt="upload Image"
                    data-test-id="upload_image"
                    onClick={this.handleImageClick}
                />
            </Grid>
        )
    }

    jdFileNameAndSizeGrid = () => {
        return (
            <Grid item xs={12} sm={9} md={9.5} sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: {
                    xs: 'center',
                    sm: 'left',
                },
                alignItems: {
                    xs: 'center',
                    sm: 'flex-start',
                },
            }}>
                {this.state.isEdit ?
                    <>
                        <Typography className="jdUploadText">
                            {this.isEditJdTemplate()}
                        </Typography>
                        <Typography className="jdUploadSubText">
                            {this.isEditJdDocumentSize()}
                        </Typography>
                    </> :
                    <>
                        <Typography className="jdUploadText">
                            {this.state.selectedFile ? this.state.selectedFile?.name :
                                `${this.props.i18n?.t("companyDetails.uploadJdText").toString()}`}
                        </Typography>
                        <Typography className="jdUploadSubText">
                            {this.state.selectedFile
                                ? `${(this.state.selectedFile?.size / 1024).toFixed(2)} KB`
                                : `${this.props.i18n?.t("companyDetails.uploadSubText").toString()}`}
                        </Typography>
                    </>
                }
            </Grid>
        )
    }

    jdTemplates = () => {
        const errorInFile = (!this.state.isEdit && !this.state.selectedFile) ||
            (this.state.isEdit && ((this.state.uploadedFile || "").length < 1 && !this.state.selectedFile))
        const errorRequired = this.state.submitClicked && errorInFile && this.props.i18n?.t("companyDetails.errorText").toString() || "";
        const errorMB = this.state.fileError.length > 0 && this.state.fileError || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.props.i18n?.t("companyDetails.uploadJD").toString()} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {errorMB}
                        {!errorMB && errorRequired}
                    </Typography>
                </Box>
                <Box className={`${(errorRequired.length > 0 || errorMB.length > 0) ? "errorClass" : ""} jdMainBox`}>
                    <Grid container alignItems={"center"} spacing={1}>
                        {this.jdImageGrid()}

                        {this.jdFileNameAndSizeGrid()}

                        <Grid item xs={12} sm={1} className="deleteJdImg">
                            <img src={more.default}
                                alt="Delete document"
                                onClick={this.handleFileDelete}
                                data-test-id="delete_template"
                            />
                        </Grid>
                    </Grid>
                    <input
                        id="upload-jd-input"
                        type="file"
                        accept=".pdf,.doc,.docx"
                        style={{ display: 'none' }}
                        data-test-id="upload_template"
                        onChange={this.handleFileUpload}
                    />
                </Box>
            </Wrapper>
        )
    }

    submitButton = () => {
        return (
            <Wrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Button className="sumbmitBtn" data-test-id="submit_company_details" onClick={this.handleCompanyDetailSubmit}>
                            <Typography className="sumbmitText">
                                {this.state.isEdit
                                    ? this.props.i18n?.t("companyDetails.updateBtn").toString()
                                    : this.props.i18n?.t("companyDetails.submitBtn").toString()}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>

            </Wrapper>
        )
    }

    companyFormHeader = () => {
        return (
            <Wrapper>
                <Box className="companyItemMainBox">
                    <Grid container className="companyCardContainer" justifyContent={"flex-start"}>
                        <Grid item xs={12} className="companyCardItem">
                            <Card className="companyCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">                                               
                                                {this.props.i18n?.t("companyDetails.organizationDetails").toString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="contentPadding">
                                        <Grid item xs={12}>
                                            {this.organizationName()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.countryList()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.regionList()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.companyCulture()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.industryType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.companySize()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.companyStage()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.jdStyle()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.organizationKeywords()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.jdTemplates()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.submitButton()}
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    successCompanyDetailDialog = () => {
        return (
            <Dialog
                open={this.state.openCompanySuccess}
                onClose={this.handleCloseCompanySuccess}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                        borderRadius: "8px 8px 32px 8px"
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="companySuccessDialogTitle">
                            {this.state.isEdit ?
                                this.props.i18n?.t("companyDetails.orgUpdated").toString() :
                                this.props.i18n?.t("companyDetails.orgCreated").toString()}
                        </Typography>
                        <IconButton
                            data-test-id="close_company_success"
                            aria-label="close"
                            onClick={this.handleCloseCompanySuccess}
                            className="companySuccessDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="companySuccessImageBox">
                            <img src={successImg.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            padding: "14px 34px",
                            fontSize: "20px",
                            fontWeight: 700,
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {
                            this.state.isEdit ?
                                this.props.i18n?.t("companyDetails.successUpdateText").toString() :
                                this.props.i18n?.t("companyDetails.successText").toString()
                        }
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            padding: "0px 58px",
                            fontSize: "18px",
                            fontWeight: 400,
                            lineHeight: "26px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {this.props.i18n?.t("companyDetails.successSubText").toString()}
                    </Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "viewDetailButtonsBox" : "viewDetailButtonsBoxArabic"}`}>
                            <Button
                                variant="contained"
                                color="primary"
                                data-test-id="navigate_to_view_company"
                                onClick={this.navigateToViewCompany}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "viewDetailButton" : "viewDetailButtonArabic"}`}
                            >
                                {this.props.i18n?.t("companyDetails.viewDetailBtn").toString()}
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Company details" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "" : "arabicDirection"}`}>
                        <Grid container justifyContent={`${this.state.selectedLanguage.toLowerCase() === "english" ? "flex-start" : "flex-end"}`}>
                            <Grid item xs={12} md={10.5}>
                                <Grid container sx={{
                                    flexDirection: `${this.state.selectedLanguage.toLowerCase() === "english" ? "row" : "row-reverse"}`
                                }}>
                                    <Grid item xs={11}>
                                        <Grid container spacing={1} alignItems={"center"} sx={{paddingLeft: "8px"}}>
                                            <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                                <img src={backArrow.default}
                                                    alt="Back Arrow"
                                                    className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "backArrowImg" : "backArrowImgArabic"}`}
                                                    onClick={this.navigateToViewCompany}
                                                />
                                            </Grid>
                                            <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>

                                                <Typography
                                                    className={`${this.state.selectedLanguage.toLowerCase() ===
                                                        "english" ? "CompanyTitle" : "companyTitleArabic"}`}
                                                >
                                                    {this.props.i18n?.t("companyDetails.organizationDetails").toString()}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Grid container spacing={1} className="compMainHeaderGrid">
                                            <Grid item xs={11.5} md={11.2} lg={11.5}>
                                                {this.companyFormHeader()}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {this.successCompanyDetailDialog()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const CompanyDetailsWeb = withTranslation()(CompanyDetails)
export const Wrapper = styled(Box)({
    width: "100%",
    "& .arabicDirection": {
        direction: "rtl",
    },
    "& .companyCardDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "55vw",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .companyItemMainBox": {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
    },
    "& .companyCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .cardTitle": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .userEnterInputField": {
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontSie: '16px',
        fontWeight: 600,
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        height: '66px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .cardInputLabel": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        linHheight: "22px",
        color: "#344054",
        marginBottom: "10px"
    },
    "& .errorStyle": {
        color: "#F87171",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "italic"
    },
    "& .errorStyleMainbox": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .companySuccessDialogClose": {
        top: 16,
        position: 'absolute',
        color: "#334155"
    },
    "& .viewDetailButtonsBox": {
        margin: "16px 0px",
        paddingRight: "16px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .viewDetailButton": {
        height: "56px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        width: "30%",
        padding: "16px 24px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .viewDetailButtonArabic": {
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "31%",
        height: "56px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .viewDetailButtonsBoxArabic": {
        display: "flex",
        justifyContent: "flex-end",
        paddingLeft: "16px",
        margin: "16px 0px"
    },
    "& .companySuccessImageBox": {
        padding: "10px 0px",
        display: "flex",
        justifyContent: "center"
    },
    "& .companySuccessDialogTitle": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        margin: "8px 16px",
        color: "#1E1E1E"
    },
    "& .companyCardContainer": {
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .chipContainer": {
        borderTop: '1px solid #CBD5E1',
        padding: "12px 16px",
    },
    "& .chip": {
        border: '1px solid #CBD5E1',
        margin: "12px 12px 12px 0px",
        borderRadius: "8px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500,
        color: "#475361"
    },
    "& .chipArabic": {
        border: '1px solid #CBD5E1',
        margin: "12px 12px 12px 0px",
        borderRadius: "8px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500,
        color: "#475361",
        paddingLeft: "8px"
    },
    "& .organizationKeywords": {
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: "8px"        
    },
    "& .orgKeywordInput": {
        border: 0, padding: "24px 12px 24px"
    },
    "& .jdMainBox": {
        display: "flex",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "20px 5px"
    },
    "& .jdInnerGrid": {
        display: "flex",
        justifyContent: "center"
    },
    "& .deleteJdImg": {
        display: "flex",
        justifyContent: "center"
    },
    "& .jdUploadText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#0F172A"
    },
    "& .jdUploadSubText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#64748B",
        direction: "ltr"
    },
    "& .errorClass": {
        border: '1px solid #F87171 !important',
    },
    "& .cultureInputField": {
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: "8px",
        height: "98px",
        padding: "12px 16px",
        width: '100%',
        color: "#475569",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .companyCultureLimit": {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Urbanist",
    },
    "& .dropDownStyle": {
        backgroundColor: 'white',
        height: '66px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        fontSie: '16px',
        fontWeight: 700,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 12px',
        width: '100%',
        color: "#475569"
    },
    "& .dropDownStyleplaceHolder": {
        color: "#DCDCDC",
        fontSize: 16,
        fontWeight: 400
    } as React.CSSProperties,

    "& .sumbmitBtn": {
        height: "65px",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px 0px 0px 0px",
        background: "#044352",
        width: "100%"
    },
    "& .sumbmitText": {
        fontFamily: "Urbanist",
        fontFize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "none",
        color: "#FFFFFF"

    },
    "& input::placeholder, textarea::placeholder": {
        color: "#cccccc"
    }
})
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .arabicDirection": {
        direction: "rtl",
    },
    "& .companyTitle": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        color: "#000104",
        marginLeft: "16px",
        "@media(max-width: 599px)": {
            marginLeft: "10px",
        }
    },
    "& .companyTitleArabic": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        color: "#000104",
        marginRight: "26px"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .backArrowImgArabic": {
        transform: "rotate(180deg)"
    },
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
})
// Customizable Area End