import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface QuestionBankData {
    data?: {
        id: string;
        type: string;
        attributes: {
            selected_question_types: string[];
            selected_question_formats: string[];
            selected_difficulty_levels: string[];
            selected_focus_areas: string[];
            questions: Questions[];
        }
    }
}
interface QuestionBankResponse {
    message: string;
    error: string;
    question_bank: QuestionBankData;
}

export interface Questions {
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            text: string;
            answer_type: string;
            question_bank_id: string;
            answer: {
                id: string;
                answer_type: string;
                choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
                correct_choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
            };
        };
    };
}

interface Choices {
    id?: string;
    text?: string;
    answer_type?: string;
    question_bank_id?: string;
    answer?: {
        id: string;
        answer_type: string;
        choices: {
            id: string;
            option_text: string;
            correct: boolean;
        }[];
        correct_choices?: {
            id: string;
            option_text: string;
            correct: boolean;
        }[];
    };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    questionBankId: string;
    errorMsg: string;
    questionId: string;
    editQuestion: string;
    editOptions: {
        id: string;
        option_text: string;
        correct: boolean;
    }[];
    questionBankData: Choices[];
    updateDialog: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditQuestionBankController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listQuestionApiCallId: string = "";
    editQuestionApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            selectedLanguage: "",
            errorMsg: "",
            questionBankId: "",
            questionId: "",
            editQuestion: "",
            questionBankData: [],
            editOptions: [],
            updateDialog: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.listQuestionApiCallId) {
                this.listQuestionApiResponse(responseJson)
            }
            if (apiRequestCallId === this.editQuestionApiCallId) {
                this.editQuestionApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const questionId = await getStorageData("editQuestionId");
        const parsedQuestionId = await JSON.parse(questionId)
        this.setState({ questionId: parsedQuestionId });

        const questionBankId = await getStorageData("questionBankId");
        const parsedId = await JSON.parse(questionBankId)
        this.setState({ questionBankId: parsedId }, () => {
            this.listQuestionApiCall()
        });

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
    }

    listQuestionApiResponse = (responseJson: QuestionBankResponse) => {
        if (responseJson && !responseJson.error) {
            const questionResponse = responseJson.question_bank.data?.attributes.questions || [];
            const matchingQuestions = questionResponse && questionResponse.filter(
                (options) => options.data?.id == this.state.questionId);
            this.setState({
                editOptions: matchingQuestions[0].data?.attributes.answer.choices || []
            })

            if (matchingQuestions.length) {
                const currentQuestion = matchingQuestions[0]?.data?.attributes;
                this.setState({
                    questionBankData: currentQuestion ? [currentQuestion] : []
                }, () => {
                    const plainText = this.state.questionBankData?.[0]?.text?.replace(/^Question:\s*/, "") || "";
                    this.setState({ editQuestion: plainText })
                })
            }

        } else {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    editQuestionApiResponse =(responseJson: QuestionBankResponse) => {
        if (responseJson && !responseJson.error) {
            this.navigateToListing();
        } else {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    listQuestionApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.listQuestionApiCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.questionListApiEndPoint}/${this.state.questionBankId}`
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }

    editQuestionApiCall = () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };

        const rawBody = JSON.stringify({
            "question_bank_id": parseInt(this.state.questionBankId),
            "question": {
                "text": this.state.editQuestion,
                "answer_type": "Multiple choice"
            },
            "choices": this.state.editOptions
        });

        const questionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editQuestionApiCallId = questionMsg.messageId;
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            rawBody
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editQuestionApiEndPoint}/${this.state.questionId}`
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(questionMsg.id, questionMsg);
    }

    questionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ editQuestion: event.target.value });
    }

    handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, id: string): void => {
        const updatedOptions = this.state.editOptions.map(option => {
            if (option.id === id) {
                return {
                    ...option,
                    option_text: event.target.value
                };
            }
            return option;
        });

        this.setState({
            editOptions: updatedOptions
        });
    };

    handleUpdateClose = () => {
        this.setState({ updateDialog: false})
    }

    handleEditQuestion = () => {
        this.setState({updateDialog: true})
    }

    navigateToListing = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ListingQuestionBank");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}