import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";

interface UserProfile {
    message?: string;
    errors?: string;
    data?: {
        id: string;
        attributes: {
            activated: boolean;
            country_code: string;
            email: string;
            first_name: string;
            full_phone_number: string;
            last_name: string;
            phone_number: string;
            staff_id: string;
            date_of_birth: string;
            gender: string;
            designation: string;
            company_name: string;
            user_role: string;
            job_description: string;
            profile_picture: string;
            department: {
                id: string;
                attributes: {
                    id: string;
                    name: string;
                }
            }
            competencies: {
                id: string;
                name: string;
            }[]
        },        
    }
}

interface UserStatus {
    errors?: string;
    message?: string;
    account_id?: string;
    activated?: boolean;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    userId: string;
    errorMsg: string;
    userProfileData: UserProfile;
    loader: boolean;
    userStatusData: UserStatus;
    openDeActivateUser: boolean;
    selectedLanguage: string;
    checkedDeactivate: boolean;
    deActivateBtnClicked: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewUserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    viewUserProfileApiCallId: string = "";
    userStatusApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            userId: "",
            errorMsg: "",
            userProfileData: {},
            loader: false,
            userStatusData: {},
            openDeActivateUser: false,
            selectedLanguage: "",
            checkedDeactivate: false,
            deActivateBtnClicked: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.viewUserProfileApiCallId) {
                this.handleUserApiResponse(responseJson)
            }
            if (apiRequestCallId === this.userStatusApiCallId) {
                this.handleUserStatusApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const userId = await getStorageData("userId");
        const parsedUserId = await JSON.parse(userId)
        this.setState({ userId: parsedUserId })

        this.viewUserProfileApiCall();
    }

    handleUserApiResponse = (responseJson: UserProfile) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                userProfileData: responseJson,
                loader: false
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loader: false
            });
        }
    }

    handleUserStatusApiResponse = (responseJson: UserStatus) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ userStatusData: responseJson }, () => {
                this.navigateToCreateUser();
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loader: false
            });
        }
    }

    viewUserProfileApiCall = () => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };
        const getViewUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.viewUserProfileApiCallId = getViewUserMsg.messageId;
        getViewUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getViewUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.viewUserApiEndPoint}/${this.state.userId}`
        );
        getViewUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getViewUserMsg.id, getViewUserMsg);
    }

    handleUserStatus = () => {
        this.setState({ deActivateBtnClicked: true })
        if (!this.state.checkedDeactivate) {
            return
        }
        const headers = {
            "token": this.state.token
        };
        const getUserStatusMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.userStatusApiCallId = getUserStatusMsg.messageId;
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userStatusApiEndPoint}/${this.state.userId}/${configJSON.changeStatus}?status=${!this.state.userProfileData.data?.attributes.activated}`
        );
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
    }

    closeDeActivateUser = () => {
        this.setState({ openDeActivateUser: false })
    }

    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ checkedDeactivate: event.target.checked });
    };

    deactivateUserDialogOpen = () => {
        this.setState({ openDeActivateUser: true })
    }

    navigateToCreateUser = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "AllUsersDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToEditUser = async (id: string) => {
        await setStorageData("userId", JSON.stringify(id))
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "UserCreation");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}