import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";

interface DeleteQuestionBankResp {
    error: string;
    message: string;
}
interface AllQuestionListResponse {
    error: string;
    message: string;
    question_banks: QuestionData[];
}
interface QuestionData {
    data?: {
        id: string;
        type: string;
        attributes: {
            competencies_and_question_counts: {
                job_title: string;
                competencies: string;
                total_questions_count: string;
            }[];
        }
    };
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedTab: string;
    anchorEl: HTMLElement | null;
    viewMoreAnchorEl: HTMLElement | null;
    selectedRowId: string | null;
    selectedViewRowId: string | null;
    selectedLanguage: string;
    token: string;
    errorMsg: string;
    questionListData: QuestionData[];
    loader: boolean;
    opendialog: boolean;
    deleteRowId: string;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class QuestionBankDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listQuestionApiCallId: string = "";
    deleteQuestionBankCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedTab: "",
            anchorEl: null,
            viewMoreAnchorEl: null,
            selectedRowId: null,
            selectedViewRowId: null,
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            questionListData: [],
            loader: false,
            opendialog: false,
            deleteRowId: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.listQuestionApiCallId) {
                this.listQuestionApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteQuestionBankCallId) {
                this.deleteQuestionApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        this.QuestionListApiCall();
    }

    listQuestionApiResponse = (responseJson: AllQuestionListResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                questionListData: responseJson.question_banks ? responseJson.question_banks : [],
                loader: false
            })
        } else {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }

    deleteQuestionApiResponse = (responseJson: DeleteQuestionBankResp) => {
        if (responseJson && !responseJson.error) {
            this.setState({ deleteRowId: "", opendialog: false }, () => {
                this.QuestionListApiCall();
            })

        } else {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    QuestionListApiCall = () => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.listQuestionApiCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.questionListApiEndPoint}`
        );

        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }

    deleteQuestionBankApiCall = (id: string) => {
        const headers = {
            "token": this.state.token
        };
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteQuestionBankCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteQuestionBankApiEndPoint}/${id}`
        );

        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }

    handleTabSelction = (tabText: string) => {
        this.setState({ selectedTab: tabText }, () => {
            let route = "";
            if (this.state.selectedTab === "CreateNewQuestion") {
                route = "QuestionBankCreation";
            }
            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        })
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedRowId: rowId
        });
    };

    handleViewMoreMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            viewMoreAnchorEl: event.currentTarget,
            selectedViewRowId: rowId
        });
    };

    handleViewMoreMenuClose = () => {
        this.setState({
            viewMoreAnchorEl: null,
            selectedViewRowId: null
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            selectedRowId: null
        });
    };

    handleCloseDelete = () => {
        this.setState({ opendialog: false })
    }

    handleDelete = (rowId: string) => {
        this.setState({ opendialog: true, deleteRowId: rowId }, 
            ()=>{
                this.handleMenuClose();
            }
        )
    }

    handleView = async (id: string) => {
        await setStorageData("questionBankId", JSON.stringify(id));

        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ListingQuestionBank");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);

        this.handleMenuClose();
    }

    // Customizable Area End
}