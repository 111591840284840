import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData , removeStorageData, setStorageData} from '../../../framework/src/Utilities';
import { SelectChangeEvent } from '@mui/material';
import * as Yup from "yup";

import React from "react";
import { WithTranslation } from "react-i18next";
import { i18n } from "i18next";

interface UserProfile {
  data?: {
    id: string;
    type: string;
    attributes: {
      id: string;
      company_name: string;
      "photo": string;
      "full_name": string;
      "email": string;
      "linkedin": string | null;
      "twitter": string | null;
      "website": string | null;
      "date_of_birth": string | null;
    }
  }
}

interface CountryCodes {
  country_name: string;
  country_code: string;
  region: string;
}
interface ContryCodeResponse {
  message?: string;
  error?: string;
  data?: CountryCodes[];
}

interface DeleteProfilePhoto {
  error?: string;
  message?: string;
}

interface Touched {
  firstName?: boolean;
  lastName?: boolean;
  emailForHelp?: boolean;
  helpRequest?: boolean;
  phoneNumber?: boolean;
}

interface Errors {
  firstName: string;
  lastName: string;
  emailForHelp: string;
  helpRequest: string;
  phoneNumber: string;
}

interface RatingScaleData {
  data: RatingScale[];
}

interface RatingScale {
  id: string;
  type: string;
  attributes: RatingScaleAttributes;
}

interface RatingScaleAttributes {
  level_name: string;
  description: string;
  rating_scale_type: string;
  account_id: number;
  created_at: string;
  updated_at: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  closeLogout: () => void ;
  i18n?: i18n
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  profileSettingScreen: boolean;
  notificationSettingScreen: boolean;
  updateNotification: boolean;
  logoutProfile:boolean;
  deleteAccount:boolean;
  deleteSuccess: boolean;
  changePassword:boolean;
  defineRatingAction:number;
  authToken: string;
  newPasswords: string;
  confirmPasswords: string;
  currentPassword: string;
  currentPasswordResponse:boolean;
  ChangedOpen:boolean;
  jobLevelPlusButton:boolean;
  profileId:string;
  talentActiveTab:string;
  ActiveTabText: string;
  ActiveTabTextRender:string;
  IsDeleteModelOpen:boolean,
  TalentProfileDeleteId:string,
  LevelName:string,
  LevelDescription:string;
  LevelOrder:string;
  RatingScaleFormReinitialize: boolean,
  JobFamilyFormReinitialize: boolean,
  JobFamilyName:string;
  JobFamilyDescription:string;
  IsJobFamilyFormEdit:boolean;
  EditJobFamilyId: string;
  IsRatingFormEdit:boolean;
  IsRatingFormEditId: string;
  JobLevelName: string,
  JobLevelDescription: string,
  EditJobLevelId: string,
  IsJobLevelFormEdit: boolean,
  JobLevelFormReinitialize: boolean,
  SuccessModelForProfile: boolean,
  SuccessLabelText: string,
  SuccessUpdateOrCreate:string,
  defineTab:string;
  DefineTabType:string;
  DefineTabTypeTraslationText:string;
  userProfileData: UserProfile;
  deleteProfilePhoto: DeleteProfilePhoto;
  imageRef: React.RefObject<HTMLInputElement>;
  imageUpload: FileList | null;
  profileImage: string[];
  referenceImage: string;
  languageSettingScreen: boolean;
  languageChange: string[];
  selectedLanguage: string;
  CurrentLanguage: string;
  IsItEnglish: boolean;
  selectedCountryCode: string;
  languageChangeSuccess: boolean;
  rolespermissionSettingScreen: boolean;
  activeTab: string;
  ActiveTabStringForSearch: string;
  SearchTextEmployee: string,
  SearchTextCandidate:string,
  helpSupportSettingScreen:boolean;
  countryCodeData:ContryCodeResponse;
  helpSupportChangeSuccess: boolean;
  firstName:string;
  lastName:string;
  emailForHelp:string;
  helpRequest:string;
  phoneNumber:string; 
  plusButton:boolean;
  jobFamilyPlusButton:boolean;
  jobFamilyAction: number;
  touched: Touched;
  errors: Errors;
  companyDetails: boolean,
  JobDescription: boolean,
  ModelShowEmployeeData: Array<{
    department: string, designation: string, email: string, firstName: string, id: string, lastName: string
  }>,
  ModelShowMangeEmployeData: Array<{
    department: string, 
    designation: string, email: string, firstName: string, id: string, lastName: string
  }>,
  RatingScaleData: Array<{
    id: string,
    level_name: string, description: string, rating_scale_type: string, account_id: string, created_at: string, updated_at: string, type: string,
  }>;
  JobFamilyData: Array<{
    id: number;
    name_ar: string;
    name_en: string;
    account_id: string;
    created_at: string;
    updated_at: string;
    description: string;
  }>,
  JobLevelData:Array<{
    id: string;
    name_ar: string;
    name_en: string;
    account_id: string;
    created_at: string;
    updated_at: string;
    description: string;
  }>,
  EmployeeRolePagination:number,
  PerPageDataRender:number,
  TotalCandidateRecord:number,
  TotalEmployeeRecord:number,
  CandidateRolePagination:number,
  HrSubAdminSuccessModelState:boolean,
  manageAllUsers: boolean,
  competencies: boolean,
  CriticalPosition: boolean,
  questionBank: boolean,
  jobLevelAction: number,
  assessments: boolean,
  employeeRole: any,
  selectedEmployees: any,
  openSuccessComp:boolean
  selectedRoleId:any,
  candidateRole: any,
  selectedCandidate: any,
  selectedCandidateRoleId:any,
  openSuccessCandidateComp:boolean
  employeeDataPopup:any
  candidateDataPopup:any

  talentProfileSettingScreen:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgetPasswordApiCall: string = "";
  getCurrentPassApiCallId: string = "";
  showUserProfileApiCallId: string ="";
  getUserAccountDeleteApiCallId: string ="";
  deleteProfilePhotoApiCallId: string ="";
  updateProfilePhotoApiCallId: string = "";
  languageChangeApiCallId: string ="";
  createhelpSupportApiCallId: string ="";
  hrSubAdminApiCallId: string= "";
  updateHrSubAdminApiCallId: string= "";
  manageEmployeeApiCallId: string= "";
  updatemanageEmployeeApiCallId: string= "";
  manageCandidateApiCallId: string= "";
  updatemanageCandidateApiCallId: string= "";
  getAllCountryCodeApiCallId: string= "";
  GetTalentProfileData: string= "";
  RatingFormCreateApiCall: string= "";
  RatingFormEditApiCall: string= "";
  GetJobFaimlyApiCallId: string= "";
  GetJobLevelApiCallId: string= "";
  JobFamilyFormCreateApiCall: string= "";
  JobFamilyFormEditApiCall: string= "";
  JobLevelFormCreateApiCall: string= "";
  JobLevelFormEditApiCall: string= "";
  DeleteApiCall: string= "";
  SearchEmployeApiCallId: string= "";
  SearchCandidateApiCallId: string= "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      profileSettingScreen: true,
      referenceImage: "",
      notificationSettingScreen: false,
      updateNotification:false,
      logoutProfile:false,
      deleteAccount:false,
      deleteSuccess:false,
      changePassword:false,
      imageUpload: null,  
      profileImage: [],
      authToken: "",
      newPasswords: "",
      confirmPasswords: "",
      currentPassword: "",
      currentPasswordResponse:false,
      plusButton:false,
      jobFamilyPlusButton:false,
      jobFamilyAction:-1,
      jobLevelAction: -1,
      ChangedOpen:false,
      jobLevelPlusButton:false,
      profileId:"",
      userProfileData: {},
      deleteProfilePhoto:{}, 
      countryCodeData:{},
      imageRef: React.createRef(),
      languageSettingScreen:false,
      languageChange:["English" , "عربي"],
      selectedLanguage: "English",
      CurrentLanguage: getStorageData('i18nextLng') as unknown as string,
      IsItEnglish: true,
      selectedCountryCode: "+91",
      languageChangeSuccess: false,
      rolespermissionSettingScreen:false,
      activeTab: 'HR SubAdmin',
      ActiveTabStringForSearch:'HR SubAdmin',
      SearchTextEmployee:"",
      SearchTextCandidate:"",
      talentActiveTab:"DefineRating",
      ActiveTabText: "Rating Scale",
      ActiveTabTextRender:this.TraslationFuncation("SettingsPage.RatingScale"),
      LevelName:"",
      IsDeleteModelOpen: false,
      TalentProfileDeleteId:'',
      LevelDescription:"",
      LevelOrder:"",
      RatingScaleFormReinitialize:false,
      JobFamilyFormReinitialize:false,
      JobFamilyName: "",
      JobFamilyDescription: "",
      EditJobFamilyId: "",
      IsJobFamilyFormEdit:false,
      IsRatingFormEdit:false,
      IsRatingFormEditId:"",
      JobLevelName: "",
      JobLevelDescription: "",
      EditJobLevelId: "",
      IsJobLevelFormEdit:false,
      JobLevelFormReinitialize:false,
      SuccessModelForProfile: false,
      SuccessLabelText: '',
      SuccessUpdateOrCreate: '',
      defineTab:"genRating",
      DefineTabType:"generic",
      DefineTabTypeTraslationText:this.TraslationFuncation("SettingsPage.generic"),
      helpSupportSettingScreen:false,
      helpSupportChangeSuccess:false,
      firstName:"",
      lastName:"",
      emailForHelp:"",
      helpRequest:"",
      phoneNumber:"",
      touched: {},
      errors:{
        firstName:"",
        lastName:"",
        emailForHelp:"",
        helpRequest:"" ,
        phoneNumber:""      
      },
      companyDetails: false,
  JobDescription: false,
  defineRatingAction: -1,
  manageAllUsers: false,
  competencies: false,
  CriticalPosition: false,
  questionBank: false,
  assessments: false,
  employeeRole:[],
  selectedEmployees: [],
  openSuccessComp:false,
  selectedRoleId:[],
  candidateRole: [],
  selectedCandidate: [],
  selectedCandidateRoleId:[],
  openSuccessCandidateComp:false,
  employeeDataPopup:[],
  candidateDataPopup:[],
  ModelShowEmployeeData:[],
  ModelShowMangeEmployeData:[],
  RatingScaleData:[],
  JobFamilyData:[],
  JobLevelData:[],
  EmployeeRolePagination:1,
  PerPageDataRender:5,
  TotalCandidateRecord:0,
  TotalEmployeeRecord:0,
  CandidateRolePagination:1,
  HrSubAdminSuccessModelState: false,
  talentProfileSettingScreen:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.forgetPasswordApiCall){
    }
    if(apiRequestCallId === this.getCurrentPassApiCallId){

      this.setState({currentPasswordResponse:responseJson.matched})
    }
    if (apiRequestCallId === this.showUserProfileApiCallId) {
      this.handleUserProfileApiResponse(responseJson)
    }
    if (apiRequestCallId === this.deleteProfilePhotoApiCallId) {
      this.handleDeletePhotoApiResponse(responseJson)
    }
    if(apiRequestCallId === this.updateProfilePhotoApiCallId){
    }
    this.HrSubAdminApicallResponseSet(apiRequestCallId,responseJson)
    this.TalentApiCallSetResponseFuncation(apiRequestCallId,responseJson)
    this.CreateApiCallResponseSet(apiRequestCallId,responseJson)
    this.RatingFormEditApiResponseSet(apiRequestCallId,responseJson)
    this.SetGetJobFaimlyApiCallResponse(apiRequestCallId,responseJson)
    this.JobFamilyEditAndCreateApiCallResponse(apiRequestCallId,responseJson)
    this.SetGetJobLevelApiCallResponse(apiRequestCallId,responseJson)
    this.EditAndCreateJobLevelApiResposne(apiRequestCallId,responseJson)
    this.SetSearchEmployeApiResponse(apiRequestCallId,responseJson)
    this.SetSearchCandidateApiResponse(apiRequestCallId,responseJson)
    this.SetDeleteApiCall(apiRequestCallId,responseJson)
    this.settingRevieve(apiRequestCallId ,responseJson )
    this.settingRecieve(apiRequestCallId ,responseJson )
    // Customizable Area End

  }
  txtInputWebProps = {
    onChangeText: (text: string) => {this.setState({ txtInputValue: text });
    },secureTextEntry: false,
  };
  txtInputMobileProps = {...this.txtInputWebProps,autoCompleteType: "email", keyboardType: "email-address",};
  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;
 btnShowHideProps = {
    onPress: () => {this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = 
      !this.state.enableField;
      this.btnShowHideImageProps.source = 
      this.txtInputProps.secureTextEntry
        ? imgPasswordVisible: imgPasswordInVisible;
    },};

  btnShowHideImageProps = {source: this.txtInputProps.secureTextEntry ? imgPasswordVisible
      : imgPasswordInVisible,};

  btnExampleProps = { onPress: () => this.doButtonPressed(),};
doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess)); message.addData(
      getName(MessageEnum.AuthTokenDataMessage),this.state.txtInputValue
    ); this.send(message);}

  // web events
  setInputValue = (text: string) => {this.setState({ txtInputValue: text });};

  setEnableField = () => {this.setState({ enableField: !this.state.enableField });};

  // Customizable Area Start

  async componentDidMount() {
    const tokenMeta = await getStorageData('signInResponse', true);
    this.setState({
      authToken: tokenMeta?.meta?.token,
      profileId: tokenMeta?.meta?.profile_id,
    },async ()=>{
      setTimeout(async () => {
        const CurrentLanguage = await getStorageData('i18nextLng');
        this.setState({
          CurrentLanguage: CurrentLanguage,
          IsItEnglish: CurrentLanguage === 'en' ? true : false,
        })
      }, 100);
    })

    this.showUserProfileApiCall()
    this.setState({ activeTab: "HRSubadmin" });
    this.hrSubAdminGetApiCall()
    this.manageEmployeeGetApiCall()
    this.manageCandidateGetApiCall()
    this.getAllCountryCodeApiCall();
    this.GetTalentProfileDataFuncation();
    this.GetJobFaimlyApiCallFuncation()
    this.GetJobLevelApiCallFuncation()
  }

  apiResetPasswords = () => {
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const httpBody: any = {
      "password": this.state.newPasswords,
      "password_confirmation": this.state.confirmPasswords

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.forgetPasswordApiCall = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resetPasswordEndPoint}?token=${this.state.authToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  apiCurrentPasswordGetCall = (currentPassword: string) => {
    const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrentPassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCurrentPassApiEndPoint}=${currentPassword}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ConditionValuePass = (condition:boolean, TrueCondition:string, FalseCondition: string ) =>{
    return condition ? TrueCondition : FalseCondition
  }

  showUserProfileApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showUserProfileApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showUserProfileApiEndPoint}/${this.state.profileId}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  userAccountDeleteGetCall = () => {
    
    const headers = {
      "token": this.state.authToken,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserAccountDeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserAccountDeleteAPiEndPoint}/${this.state.profileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationDeleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteProfilePhotoApiCall = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProfilePhotoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProfilePhotoApiEndpoint}/${this.state.profileId}/delete_photo`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationDeleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  languageChangeApiCall = () => {
    const headers = {
        "token": this.state.authToken
    };
    const getUserStatusMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.languageChangeApiCallId = getUserStatusMsg.messageId;
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.languageChangeApiEndPoint}=${this.state.selectedLanguage === "English" ? "en" : "ar"}`
    );
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

helpSupportCall = async() => {
    
  const headers = {
    "token": this.state.authToken,
    "Content-Type": "application/json",
  };
  let phoneNumber = "";
  const regex = /^\d+$/;
  if (regex.test(this.state.phoneNumber)) {
    phoneNumber = this.state.selectedCountryCode + this.state.phoneNumber;
  };
  const httpBody: any = {
    "help_and_support": {
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "phone_number": phoneNumber,
      "email": this.state.emailForHelp,
      "help_request": this.state.helpRequest
  }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.createhelpSupportApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createHelpSupportApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
hrSubAdminGetApiCall = () => {
  const headers = {
      "token": this.state.authToken
  };
  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.hrSubAdminApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hrsubAdminApiEndPoint}`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

updateHrSubAdminApiCall = () => {
  const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
  };
  const httpBody: any = {
    "setting": {
      "company_details": this.state.companyDetails,
      "job_description": this.state.JobDescription,
      "manage_all_users": this.state.manageAllUsers,
      "competencies": this.state.competencies,
      "critical_positions": this.state.CriticalPosition,
      "question_bank": this.state.questionBank,
      "assessments": this.state.assessments
    }
  }
  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updateHrSubAdminApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatehrsubAdminApiEndPoint}`
  );
  getUserStatusMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

HrSubAdminApicallResponseSet = (apiRequestCallId:string, response:{ meta:{message :string} }) =>{
  if (apiRequestCallId === this.updateHrSubAdminApiCallId) {
    if (response.meta.message === "Setting updated successfully.") {
      this.setState({
        HrSubAdminSuccessModelState: true
      })
    }
  }
}

HrSubAdminSuccessModelStateClose = () => {
  this.setState({
    HrSubAdminSuccessModelState: false
  })
}

handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  if (event.key === 'Enter') {
    if (this.state.activeTab === "ManageEmployeeData") {
      this.SearchEmployeeApiCall()
    }
    if (this.state.activeTab === "ManageCandidates") {
      this.SearchCandidateApiCall()
    }
  }
}

manageEmployeeGetApiCall = () => {
  const headers = {
      "token": this.state.authToken
  };
  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.manageEmployeeApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.manageEmployeeApiEndPoint}employee&page=${this.state.EmployeeRolePagination}&per_page=${this.state.PerPageDataRender}&search=${this.state.SearchTextEmployee}`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

updateManageEmployeeApiCall = (id: any[]) => {
  const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
  };

  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updatemanageEmployeeApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatemanageEmployeeApiEndPoint}?current_tab=manage_employee&user_ids=[${id}]`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

manageCandidateGetApiCall = () => {
  const headers = {
      "token": this.state.authToken
  };
  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.manageCandidateApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.manageEmployeeApiEndPoint}candidate&page=${this.state.CandidateRolePagination}&per_page=${this.state.PerPageDataRender}&search=${this.state.SearchTextCandidate}`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

updateManageCandidateApiCall = (id: any[]) => {
  const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
  };

  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updatemanageCandidateApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatemanageEmployeeApiEndPoint}?current_tab=manage_candidates&user_ids=[${id}]`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

getAllCountryCodeApiCall = () => {
  const headers = {
      "Content-Type": "application/json",
  };
  const getMsgForCountryCode = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAllCountryCodeApiCallId = getMsgForCountryCode.messageId;
  getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryCodeApiEndPoint}`
  );
  getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
  );
  runEngine.sendMessage(getMsgForCountryCode.id, getMsgForCountryCode);
}

  handleUserProfileApiResponse = (responseJson: UserProfile) => {
    if (responseJson) {
      this.setState({ userProfileData: responseJson })
    }
  }

  handleDeletePhotoApiResponse = (responseJson: DeleteProfilePhoto) => {
    if (responseJson && !responseJson.error) {
      
      if(this.state.imageUpload){
        this.setState({
          profileImage: [], imageUpload: null
        })
      }
      this.setState({ deleteProfilePhoto: responseJson },() => {this.showUserProfileApiCall()})
    }
  }

  handleImage = () => {
    return this.state.imageRef.current ? this.state.imageRef.current.click() : ""
  }

  updateProfilePhotoApiCall = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfilePhotoApiCallId = requestMessage.messageId;
    if(this.state.imageUpload && this.state.imageUpload?.length > 0){
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          "profile": {
            "full_name": this.state.userProfileData.data?.attributes.full_name,
            "date_of_birth": this.state.userProfileData.data?.attributes.date_of_birth,
            "company_name": this.state.userProfileData.data?.attributes.company_name,
            "website": this.state.userProfileData.data?.attributes.website,
            "linkedin": this.state.userProfileData.data?.attributes.linkedin,
            "twitter": this.state.userProfileData.data?.attributes.twitter,
            "photo": this.state.referenceImage
          }
        })
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserProfileApiEndpoint}/${this.state.profileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleImageChange= (files: FileList | null) => {
    if(files){
      this.setState({
        profileImage: [URL.createObjectURL(files[0])],
        imageUpload: files
      })
      const fileReader = new FileReader();

      fileReader.onload = () => {
        this.setState({referenceImage: fileReader.result as string}, () => this.updateProfilePhotoApiCall())
      };

      fileReader.readAsDataURL(files[0]);
    }
  }

  removeProfilePhoto = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
     this.deleteProfilePhotoApiCall();
  }

  navigateToDashboard = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  profileScreen = () => {
    this.setState({profileSettingScreen : true ,talentProfileSettingScreen:false,notificationSettingScreen : false ,
       languageSettingScreen:false , rolespermissionSettingScreen:false ,helpSupportSettingScreen:false})
  }

  notificationScreen = () => {
    this.setState({notificationSettingScreen : true,talentProfileSettingScreen:false, profileSettingScreen : false ,
       languageSettingScreen:false , rolespermissionSettingScreen:false , helpSupportSettingScreen:false})
  }

  closeNotificationPopupModal = () => {
    this.setState({updateNotification:false});
  };
  
  openNotificationPopupModal = () => {
    this.setState({updateNotification:false});
  };

  handleUpdateNotification = () => {
    this.setState({updateNotification:true});
  }

  closeLogoutProfilePopupModal = () => {
    this.setState({logoutProfile:false});
  };
  
  openLogoutProfilePopupModal = () => {
    removeStorageData("signInResponse")
    this.navigateToSignIn()
    this.setState({logoutProfile:false});
  };

  handleLogoutProfile = () => {
    this.setState({logoutProfile:true});
  }

  navigateToSignIn = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  closeDeleteAccountPopupModal = () => {
    this.setState({deleteAccount:false});
  };
  
  openDeleteAccountPopupModal = () => {
    this.handleDeleteSuccess()
    this.setState({deleteAccount:false});
  };

  handleDeleteAccount = () => {
    this.setState({deleteAccount:true});
  }

  closeDeleteSuccessPopupModal = () => {
    this.setState({deleteSuccess:false});
  };
  
  openDeleteSuccessPopupModal = () => {
    this.setState({deleteSuccess:false});
    this.navigateToEmailLogin();
  };

  handleDeleteSuccess = () => {
    this.userAccountDeleteGetCall();
    this.setState({deleteSuccess:true});
  }

  closeChangePasswordPopupModal = () => {
    this.setState({changePassword:false});
  };
  handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedCountryCode: event.target.value });
};
  
  openChangePasswordPopupModal = (password :{currentPassword: string;
    newPassword: string;
    confirmPassword: string
  }) => {
    this.setState({currentPassword:password.currentPassword ,  newPasswords:password.newPassword , confirmPasswords:password.confirmPassword}, () =>this.apiResetPasswords());
    this.setState({ChangedOpen : true ,changePassword:false })

  };
  
  handleChangePassword = () => {
      this.setState({changePassword:true});

  }

  closeChangedModal = () => {
    this.setState({ChangedOpen : false})
  }
  
  openChangedModal = () => {
    this.navigateToEmailLogin()
  }

  navigateToEmailLogin = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  languageScreen = async() => {
    const language = await getStorageData("language");
    this.setState({languageSettingScreen: true ,talentProfileSettingScreen:false, profileSettingScreen:false,
       notificationSettingScreen:false,rolespermissionSettingScreen:false, helpSupportSettingScreen:false, selectedLanguage: language})
  }

  handleLanguageChange = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedLanguage: event.target.value });
  };

  closelanguageChangeModal = () => {
    this.setState({languageChangeSuccess : false})
  }

  openlanguageChangeModal = () => {
    location.reload();
    this.setState({languageChangeSuccess : false})
  }

  handlelanguageChangeSuccess = () => {
    this.languageChangeApiCall();
    setStorageData("language", this.state.selectedLanguage);
    this.setState({languageChangeSuccess : true})
  }

  handleHelpSupportChangeSuccess = () => {
    this.handleFirstNameBlur();
    this.handleLastNameBlur();
    this.handleEmailBlur();
    this.handleHelpRequestBlur();
  
    this.setState((prevState) => prevState, () => {
      const { errors } = this.state;
      const hasErrors =
        errors && !Object.values(errors).every((ele) => ele === "");
  
      if (hasErrors) {
        console.log("Validation errors detected:", errors);
      } else {
        console.log("No validation errors. Proceeding with success flow.");
        this.helpSupportCall();
        this.setState({ helpSupportChangeSuccess: true });
      }
    });
  };
  
  closeHelpSupportModal = () => {
    this.setState({helpSupportChangeSuccess : false})
  }

  openHelpSupportModal = () => {
    this.setState({helpSupportChangeSuccess : false})
  }

  rolesPermissionScreen = () => {
    this.setState({rolespermissionSettingScreen : true , languageSettingScreen: false , 
      profileSettingScreen:false, notificationSettingScreen:false, helpSupportSettingScreen:false,talentProfileSettingScreen:false})
  }

  helpSupportScreen = () => {
    this.setState({ helpSupportSettingScreen:true,talentProfileSettingScreen:false, rolespermissionSettingScreen : false , languageSettingScreen: false , 
      profileSettingScreen:false, notificationSettingScreen:false})
  }

  handleHRGubAdminSaveSettings = () => {
    this.updateHrSubAdminApiCall()
  };
  handleManageEmployeeSaveSettings = () => {
  this.setState({openSuccessComp:true})
  };
  handleManageCandidateSaveSettings = () => {
    this.setState({openSuccessCandidateComp:true})
  }
  handleTabClick = (tabName:string,SearchText:string) => {
    this.setState({ activeTab: tabName, ActiveTabStringForSearch:SearchText });
  };

  handleTalentClick = (tabName:string, Text:string,TraslationKey:string) => {
    this.setState({ talentActiveTab: tabName, ActiveTabText:Text, ActiveTabTextRender:this.TraslationFuncation(TraslationKey) });
    this.ReSetJobFamilyForm()
    this.ResetFormData()
    this.ReSetJobLevel()
  };

  handleDefineTab = (tabName:string,type:string,TraslationKey:string) => {
    this.setState({ defineTab: tabName,DefineTabType: type, DefineTabTypeTraslationText:this.TraslationFuncation(TraslationKey),  plusButton:false });
    this.ResetFormData()
  };

  handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: e.target.value });
  };
  
  handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: e.target.value });
  };
  
  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailForHelp: e.target.value });
  };
  
  handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ phoneNumber: e.target.value });
  };
  
  handleHelpRequestChange = (e: any) => {
    this.setState({ helpRequest: e.target.value });
  };
  
  handleFirstNameBlur = () => {
    this.setState((prevState: S) => ({
      touched: { ...prevState.touched, firstName: true },
      errors: {
        ...prevState.errors,
        firstName: prevState.firstName.trim() === "" ? this.TraslationFuncation("SettingsPage.RequiredField") : ""
      }
    }));
  };
  
  handleLastNameBlur = () => {
    this.setState((prevState: S) => ({
      touched: { ...prevState.touched, lastName: true },
      errors: {
        ...prevState.errors,
        lastName: prevState.lastName.trim() === "" ? this.TraslationFuncation("SettingsPage.RequiredField") : ""
      }
    }));
  };
  
  handleEmailBlur = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.setState((prevState: S) => {
      const email = prevState.emailForHelp.trim();
      let errorMessage = "";
  
      if (email === "") {
        errorMessage = this.TraslationFuncation("SettingsPage.RequiredField");
      } else if (!emailRegex.test(email)) {
        errorMessage = this.TraslationFuncation("SettingsPage.PleaseEnterAValidEmailAddress");
      }
  
      return {
        touched: { ...prevState.touched, emailForHelp: true },
        errors: {
          ...prevState.errors,
          emailForHelp: errorMessage,
        },
      };
    });
  };

  SearchEmployee = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ SearchTextEmployee: e.target.value, EmployeeRolePagination:1, TotalEmployeeRecord:0 });
  }

  SearchEmployeeApiCall = async () =>{
    const { ActiveTabStringForSearch, SearchTextEmployee } = this.state;
    this.SearchEmployeApiCallId = await this.formApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `/bx_block_roles_permissions/account_details/all_users_listing_by_role?user_role=${ActiveTabStringForSearch}&search=${SearchTextEmployee}&page=${this.state.EmployeeRolePagination}&per_page=${this.state.PerPageDataRender}`
    });
  }

  SetSearchEmployeApiResponse = (apicallId: string, response: {
    data: Array<{
      id: number;
      first_name: string;
      last_name: string;
      designation: string;
      department: string;
      email: string;
    }>,
    meta:{
      total_records:number
    }
    message: string;
    status:number
  }) => {
    if (apicallId === this.SearchEmployeApiCallId) {
      if (response.status === 200) {
        const manageEmployeeData = response.data.map((data: {
          id: number;
          first_name: string;
          last_name: string;
          designation: string;
          department: string;
          email: string;
        }) => ({
          id: data.id,
          firstName: data.first_name,
          lastName: data.last_name,
          designation: data.designation,
          department: data.department,
          email: data.email
        }));
        this.setState({
          employeeRole: manageEmployeeData, TotalEmployeeRecord:response.meta.total_records
        })
      } else {
        this.setState({ employeeRole:[] })
        
      }

    }
  }

  SearchCandidate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ SearchTextCandidate: e.target.value, TotalCandidateRecord:0, CandidateRolePagination:1 });
  }

  SearchCandidateApiCall = async () =>{
    const { ActiveTabStringForSearch, SearchTextCandidate } = this.state;
    // ActiveTabStringForSearch
    this.SearchCandidateApiCallId = await this.formApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `/bx_block_roles_permissions/account_details/all_users_listing_by_role?user_role=${ActiveTabStringForSearch}&search=${SearchTextCandidate}&page=${this.state.CandidateRolePagination}&per_page=${this.state.PerPageDataRender}`
    });
  }

  SetSearchCandidateApiResponse = (apicallId:string, response:{
    data:Array<{
    id:number,
    first_name:string,
    last_name:string,
    designation:string,
    department:string,
    email:string
  }>,
  meta:{
    total_records:number
  },
  message: string,
  status:number
  }) => {
    if (apicallId === this.SearchCandidateApiCallId) {
      if (response.status === 200) {
        const manageCandidateData = response.data.map((data: any) => ({
          id: data.id,
          firstName: data.first_name,
          lastName: data.last_name,
          designation: data.designation,
          department: data.department,
          email: data.email
        }));
        this.setState({
          candidateRole: manageCandidateData,
          TotalCandidateRecord:response.meta.total_records
        })
      } else {
        this.setState({  candidateRole:[] })
      }
    }
  }
  
  handlePhoneNumberBlur = () => {
    const numericRegex = /^\d*$/;
    
    this.setState((prevState: S) => {
      const phoneNumber = prevState.phoneNumber.trim();
      let errorMessage = "";
  
      if (phoneNumber === "") {
        errorMessage = this.TraslationFuncation("SettingsPage.RequiredField");
      } else if (!numericRegex.test(phoneNumber)) {
        errorMessage = this.TraslationFuncation("SettingsPage.ContainOnlyNumbers");
      } else if (phoneNumber.length < 6 || phoneNumber.length > 10) {
        errorMessage = this.TraslationFuncation("SettingsPage.BetweenAndDigits");
      }
  
      return {
        touched: { ...prevState.touched, phoneNumber: true },
        errors: {
          ...prevState.errors,
          phoneNumber: errorMessage,
        },
      };
    });
  };

  handleHelpRequestBlur = () => {
    this.setState((prevState: S) => ({
      touched: { ...prevState.touched, helpRequest: true },
      errors: {
        ...prevState.errors,
        helpRequest: prevState.helpRequest.trim() === ""
          ? this.TraslationFuncation("SettingsPage.RequiredField")
          : ""
      }
    }));
  };
  
  handleCompanyDetailsChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ companyDetails: event.target.checked });
  };
  
  handleJobDescriptionChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ JobDescription: event.target.checked });
  };
  
  handleCompetenciesChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ competencies: event.target.checked });
  };
  
  handleCriticalPositionChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ CriticalPosition: event.target.checked });
  };
  
  handleManageAllUsersChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ manageAllUsers: event.target.checked });
  };
  
  handleQuestionBankChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ questionBank: event.target.checked });
  };
  
  handleAssessmentsChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ assessments: event.target.checked });
  };
  
  handleCheckboxChange = (employee:any) => {
    this.setState((prevState) => {
      const { selectedEmployees } = prevState;
      if (selectedEmployees.includes(employee.id)) {
        return {
          selectedEmployees: selectedEmployees.filter((employeeId:any) => employeeId !== employee.id),
          selectedRoleId: [employee.id],
          employeeDataPopup:{}
        };
      } else {
        return {
          selectedEmployees: [...selectedEmployees, employee.id],
          selectedRoleId: [employee.id],
          employeeDataPopup:employee
        };
      }
    });
    this.HandelMangeSelectEmplyData(employee);
  };

  HandelMangeSelectEmplyData = (employee: {
    department: string;
    designation: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
  }) => {
    const  { ModelShowMangeEmployeData } = this.state;
    if (ModelShowMangeEmployeData.map((i) => i.id).includes(employee.id)) {
      this.setState({
        ModelShowMangeEmployeData: this.state.ModelShowMangeEmployeData.filter((item: { id: string }) => `${item.id}` !== `${employee.id}`)
      }) 
    }else{
      this.setState({
        ModelShowMangeEmployeData: this.state.ModelShowMangeEmployeData.concat(employee)
      }) 
    }
  }

  handleCheckboxCandidateChange = (candidate:any) => {    
    this.setState((prevState) => {
      const { selectedCandidate } = prevState;
      if (selectedCandidate.includes(candidate.id)) {
        return {
          selectedCandidate: selectedCandidate.filter((candidateId:any) => candidateId !== candidate.id),
          selectedCandidateRoleId: [candidate.id]
        };
      } else {
        return {
          selectedCandidate: [...selectedCandidate, candidate.id],
          selectedCandidateRoleId: [candidate.id]
        };
      }
    });
    this.HandelSelectEmplyData(candidate)
  };

  HandelSelectEmplyData = (employee: {
    department: string;
    designation: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
  }) => {
    const  { ModelShowEmployeeData } = this.state;
    if (ModelShowEmployeeData.map((i) => i.id).includes(employee.id)) {
      this.setState({
        ModelShowEmployeeData: this.state.ModelShowEmployeeData.filter((item: { id: string }) => `${item.id}` !== `${employee.id}`)
      }) 
    }else{
      this.setState({
        ModelShowEmployeeData: this.state.ModelShowEmployeeData.concat(employee)
      }) 
    }
  }



  settingRevieve = (apiRequestCallId:string, responseJson : any) => {
  if (apiRequestCallId === this.hrSubAdminApiCallId) {
    
    const hrSubAdminData = responseJson.data?.attributes;
  
    if (hrSubAdminData) {    
      this.setState({
        companyDetails: hrSubAdminData.company_details,
        JobDescription: hrSubAdminData.job_description,
        manageAllUsers: hrSubAdminData.manage_all_users,
        competencies: hrSubAdminData.competencies,
        CriticalPosition: hrSubAdminData.critical_positions,
        questionBank: hrSubAdminData.question_bank,
        assessments: hrSubAdminData.assessments,
      });
    } 
  }
  if(apiRequestCallId === this.createhelpSupportApiCallId){
    if(responseJson){this.setState({
        firstName:"",lastName:"",helpRequest:"",phoneNumber:"",emailForHelp:""})
    } }
  if(apiRequestCallId === this.manageEmployeeApiCallId){
    if(responseJson?.message){
      return this.setState({
        employeeRole: []
      })
    }
    const manageEmployeeData = responseJson.data.map((data:any) => ({
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      designation: data.designation,
      department:data.department,
      email:data.email
  }));
  
    this.setState({employeeRole:manageEmployeeData, TotalEmployeeRecord: responseJson.meta.total_records })
  }
}

settingRecieve = (apiRequestCallId:string, responseJson : any) => {
  if(apiRequestCallId === this.updatemanageEmployeeApiCallId){
    if(responseJson?.message){
      this.manageEmployeeGetApiCall();
      this.setState({
        selectedRoleId: [],
        selectedEmployees: [],
        ModelShowMangeEmployeData:[]
      })
    }
  }
  if(apiRequestCallId === this.manageCandidateApiCallId){
    
    if(responseJson?.message){
      return this.setState({
        candidateRole: []
      })
    }
    const manageCandidateData = responseJson.data.map((data:any) => ({
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      designation: data.designation,
      department:data.department,
      email:data.email
  }));
  
    this.setState({candidateRole:manageCandidateData, TotalCandidateRecord: responseJson.meta.total_records })
  }
  if(apiRequestCallId === this.updatemanageCandidateApiCallId){
    if(responseJson?.message){
      this.manageCandidateGetApiCall();
      this.manageEmployeeGetApiCall();
      this.setState({
        selectedRoleId: [],
        selectedEmployees: [],
        ModelShowEmployeeData:[]
      })
    }
  }
  if (apiRequestCallId === this.getAllCountryCodeApiCallId) {
    console.log("responseJosn",responseJson)
    this.countryCodeApiResponse(responseJson)
}
}

  handleChangeRole = (data:any) => {
    this.setState({openSuccessComp:true , selectedRoleId:data.id , employeeDataPopup:data})
  }

  handleChangeCandidateRole = (data:any) => {
    this.setState({openSuccessCandidateComp:true , selectedCandidateRoleId:data.id , candidateDataPopup:data})
  }

  countryCodeApiResponse = (responseJson: ContryCodeResponse) => {
    if (responseJson && !responseJson.error) {
        this.setState({ countryCodeData: responseJson })
    }
}

  handleCloseSuccessComp = () => {
    this.setState({openSuccessComp:false})
  }

  handleOpenSuccessComp = () => {
    console.log("selectedEmployees", this.state.selectedEmployees, this.state.selectedRoleId)
    if(this.state.selectedEmployees?.length > 1){
      this.updateManageEmployeeApiCall([this.state.selectedEmployees])
    }else{
      this.updateManageEmployeeApiCall([this.state.selectedRoleId])
    }
    this.setState({openSuccessComp:false})
  }

  handleCloseCandidateSuccessComp = () => {
    this.setState({openSuccessCandidateComp:false})
  }

  handleOpenCandidateSuccessComp = () => {
    if(this.state.selectedCandidate?.length > 1){
      this.updateManageCandidateApiCall([this.state.selectedCandidate])
    }else{
      this.updateManageCandidateApiCall([this.state.selectedCandidateRoleId])
    }
    this.setState({openSuccessCandidateComp:false})
  }
  talentProfileScreen = () => {
    this.setState({ talentProfileSettingScreen:true ,helpSupportSettingScreen:false, rolespermissionSettingScreen : false , languageSettingScreen: false , 
      profileSettingScreen:false, notificationSettingScreen:false})
  }

  handleAddLevel = () => {
    this.setState({ plusButton:true, RatingScaleFormReinitialize: true},()=>{
      this.setState({
        RatingScaleFormReinitialize: false,
      })
    })
  }

  handleAddJobFamily = () => {
    this.setState({ jobFamilyPlusButton:true, JobFamilyFormReinitialize: true},()=>{
      this.setState({
        JobFamilyFormReinitialize: false,
      })
    })
  }
  handleAction=(id:string)=>{
    this.setState({
      jobFamilyAction: Number(id),
    });
  }
  handleDefineLevel=(id:number)=>{
    this.setState({
      jobLevelAction: id,
    });
  }
  handleDefineRatingAction=(id:number)=>{
    this.setState({
      defineRatingAction: id,
    });
  }
  handleAddJobLevel = () => {
    this.setState({ jobLevelPlusButton:true, JobLevelFormReinitialize: false,},()=>{
      this.setState({
        JobLevelFormReinitialize: true,
      })
    })
  }
  
  closeLevelPop = () => {
    this.setState({ jobLevelAction:-1})
  }

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart
}
formApiCall = async (apiData: { contentType?: string;
  endPoint: string;
  method: string;
  body?: object;
  token?: string;
  type?: string; }) => {
  const { contentType, method, endPoint, body, type } = apiData;
  const header = {
    "Content-Type": contentType,
    token: this.state.authToken
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  body && type !== "formData"
    ? requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    : requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

GetTalentProfileDataFuncation = async () => {
  this.GetTalentProfileData = await this.formApiCall({
    method: "GET",
    contentType: "application/json",
    endPoint: "/bx_block_settings/rating_scales"
  });
};

  TalentApiCallSetResponseFuncation = (apiRequestCallId: string, responseJson: RatingScaleData) => {
    if (apiRequestCallId === this.GetTalentProfileData) {
      if (Array.isArray(responseJson.data) && responseJson.data.length !== 0) {
        const data = responseJson.data.map((item)=>{
          return{
            id: item.id,
            level_name: item.attributes.level_name,
            description: item.attributes.description,
            rating_scale_type: item.attributes.rating_scale_type,
            account_id: `${item.attributes.account_id}`,
            created_at: item.attributes.created_at,
            updated_at: item.attributes.updated_at,
            type: item.type,
          }
        })
        this.setState({ RatingScaleData: data })
      }
    }
  }

  HandelChangeDefineRatingScale = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
    fileName: string
  ) => {
    SetFieldValue(fileName, e.target.value);
    this.setState((prevState) => ({
      ...prevState,
      [fileName]: e.target.value,
    }));
  }
  
  HandelChangeJobFamilyName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
    fileName: string
  ) => {
    SetFieldValue(fileName, e.target.value);
    this.setState((prevState) => ({
      ...prevState,
      [fileName]: e.target.value,
    }));
  }

  JobLevelSchema = () => {
    return Yup.object().shape({
      JobLevelName: Yup.string().required(this.TraslationFuncation("SettingsPage.JobLevelIsRequired")),
      JobLevelDescription: Yup.string().required(this.TraslationFuncation("SettingsPage.DescriptionIsRequired")),
    })
  }

  HandelChangeJobLevel = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
    fileName: string
  ) =>{
    SetFieldValue(fileName, e.target.value);
    this.setState((prevState) => ({
      ...prevState,
      [fileName]: e.target.value,
    }));
  }

  HandelLevelChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({ LevelOrder: e.target.value })
    SetFieldValue("LevelOrder", e.target.value);
  }

  RatingScaleSchema = () => {
    return Yup.object().shape({
      LevelName: Yup.string().required(this.TraslationFuncation("SettingsPage.LevelNameIsRequired")),
      LevelDescription: Yup.string().required(this.TraslationFuncation("SettingsPage.DescriptionIsRequired")),
      LevelOrder: Yup.string().required(this.TraslationFuncation("SettingsPage.LevelIsRequired"))
    })
  }

  JobFaimilySchema = () => {
    return Yup.object().shape({
      JobFamilyName: Yup.string().required(this.TraslationFuncation("SettingsPage.JobFamilyIsRequired")),
      JobFamilyDescription: Yup.string().required(this.TraslationFuncation("SettingsPage.DescriptionIsRequired")),
    })
  }

  ReSetJobLevel = () => {
    this.setState({
      JobLevelName: "",
      JobLevelDescription: "",
      EditJobLevelId: "",
      IsJobLevelFormEdit: false,
      JobLevelFormReinitialize: false,
      jobLevelPlusButton:false
    })
  }

  HandelEditLevel = (item:{id: string;
    name_ar: string;
    name_en: string;
    account_id: string;
    created_at: string;
    description: string;
    updated_at: string;}) =>{
      this.setState({
        JobLevelName: item.name_en,
        JobLevelDescription: item.description,
        EditJobLevelId: item.id,
        IsJobFamilyFormEdit: true,
      })
      this.handleAddJobLevel()
  }

  JobLevelFormApiCall = async (values: { JobLevelName: string, JobLevelDescription: string, }) => {
    const { JobLevelName, JobLevelDescription } = values;
    const {  IsJobFamilyFormEdit, EditJobLevelId } = this.state
    const body = {
      job_level: {
        name_en: JobLevelName,
        name_ar: JobLevelName,
        description: JobLevelDescription
      }
    }

    if (IsJobFamilyFormEdit) {
      this.JobLevelFormEditApiCall = await this.formApiCall({
        method: "PUT",
        contentType: "application/json",
        endPoint: `/bx_block_settings/job_levels/${EditJobLevelId}`,
        body: body
      });
    } else {
      this.JobLevelFormCreateApiCall = await this.formApiCall({
        method: "POST",
        contentType: "application/json",
        endPoint: `/bx_block_settings/job_levels`,
        body: body
      });
    }
  }

  EditAndCreateJobLevelApiResposne = (apicallId:string,_response:{}) =>{
    if (this.JobLevelFormCreateApiCall === apicallId) {
      this.ReSetJobLevel()
      this.GetJobLevelApiCallFuncation()
      this.setState({
        SuccessLabelText: `${this.state.ActiveTabTextRender} `,
        SuccessUpdateOrCreate: this.TraslationFuncation("SettingsPage.saved"),
      },()=>{
        this.OpenSuccessModelForProfile()
      })
    }
    if (this.JobLevelFormEditApiCall === apicallId) {
      this.ReSetJobLevel()
      this.GetJobLevelApiCallFuncation()
      this.setState({
        SuccessLabelText: `${this.state.ActiveTabTextRender} `,
        SuccessUpdateOrCreate: this.TraslationFuncation("SettingsPage.updated"),
      },()=>{
        this.OpenSuccessModelForProfile()
      })
    }
  }


  HandelEditdefineRateing = (item:{
    id: string;
    level_name: string;
    description: string;
    rating_scale_type: string;
    account_id: string;
    created_at: string;
    updated_at: string;
    type: string;
  }) => {
    this.setState({
      LevelName: item.level_name,
      LevelDescription: item.description,
      LevelOrder: "10",
      defineRatingAction: -1,
      IsRatingFormEdit: true,
      IsRatingFormEditId: item.id,
    })
    this.handleAddLevel()
  }

  replacePlaceholder = (
    template: string, 
    values: Record<string, string>
  ): string => {
    return template.replace(/{(\w+)}/g, (_, key) => values[key] || '');
  }
  

  ResetFormData = () =>{
    this.setState({
      LevelName: "",
      LevelDescription: "",
      LevelOrder: "",
      RatingScaleFormReinitialize: true,
      plusButton:false,
      IsRatingFormEditId: "",
    },()=>{
      this.setState({
        IsRatingFormEdit: false,
        RatingScaleFormReinitialize: false,
      })
    })
  }

  CreateApiCallResponseSet = (apicallId: string, responseJson: {}) => {
    if (this.RatingFormCreateApiCall === apicallId) {
      this.ResetFormData() 
      this.GetTalentProfileDataFuncation()
      let Messages = this.state.DefineTabTypeTraslationText.charAt(0).toUpperCase() + this.state.DefineTabTypeTraslationText.slice(1)
      this.setState({
        SuccessLabelText: `${Messages} ${this.TraslationFuncation("SettingsPage.RatingScale")} `,
        SuccessUpdateOrCreate: this.TraslationFuncation("SettingsPage.saved"),
      },()=>{
        this.OpenSuccessModelForProfile()
      })
    }
  }

  RatingFormEditApiResponseSet = (apicallId: string, responseJson: {}) => {
    if (this.RatingFormEditApiCall === apicallId) {
      this.ResetFormData() 
      this.GetTalentProfileDataFuncation()
      let Messages = this.state.DefineTabTypeTraslationText.charAt(0).toUpperCase() + this.state.DefineTabTypeTraslationText.slice(1)
      this.setState({
        SuccessLabelText: `${Messages} ${this.TraslationFuncation("SettingsPage.RatingScale")} `,
        SuccessUpdateOrCreate: this.TraslationFuncation("SettingsPage.updated"),
      },()=>{
        this.OpenSuccessModelForProfile()
      })
      
    }
  }

  RatingScaleFormApiCall = async (values: { LevelName: string, LevelDescription: string, LevelOrder: string }) => {
    const { LevelName, LevelDescription } = values;
    const { DefineTabType, IsRatingFormEdit, IsRatingFormEditId } = this.state
    const body = {
      "rating_scale": {
        "level_name": LevelName,
        "description": LevelDescription,
        "rating_scale_type": DefineTabType
      }
    }

    if (IsRatingFormEdit) {
      this.RatingFormEditApiCall = await this.formApiCall({
        method: "PUT",
        contentType: "application/json",
        endPoint: `/bx_block_settings/rating_scales/${IsRatingFormEditId}`,
        body: body
      });
    } else {
      this.RatingFormCreateApiCall = await this.formApiCall({
        method: "POST",
        contentType: "application/json",
        endPoint: `/bx_block_settings/rating_scales`,
        body: body
      });
    }
  }

  DeleteModelOpen = (id:string)=>{
    this.setState({IsDeleteModelOpen:true, TalentProfileDeleteId:id })
  }

  DeleteModelClose = ()=>{
    this.setState({IsDeleteModelOpen:false})
  }

  DeleteApiCalls = async () =>{
    const { ActiveTabText } = this.state
    let endPoint = "";

    if (ActiveTabText === "Rating Scale") {
      endPoint = "/bx_block_settings/rating_scales"
    }
    if (ActiveTabText === "Job Level") {
      endPoint = "/bx_block_settings/job_levels"
    }
    if (ActiveTabText === "Job Family") {
      endPoint = "/bx_block_settings/job_families"
    }

    this.DeleteApiCall = await this.formApiCall({
      method: "DELETE",
      contentType: "application/json",
      endPoint: `${endPoint}/${this.state.TalentProfileDeleteId}`
    });
  } 

  SetDeleteApiCall = (apicallId: string, _responseJson: {}) => {
    if (this.DeleteApiCall === apicallId) {
      this.setState({IsDeleteModelOpen:false, TalentProfileDeleteId: "", RatingScaleData:[], JobLevelData:[], JobFamilyData:[]})
      this.GetTalentProfileDataFuncation();
      this.GetJobFaimlyApiCallFuncation()
      this.GetJobLevelApiCallFuncation() 
      this.ResetFormData()
      this.ReSetJobFamilyForm()
      this.ReSetJobLevel()
    }
  }

  GetJobFaimlyApiCallFuncation = async () => {
    this.GetJobFaimlyApiCallId = await this.formApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: "/bx_block_settings/job_families"
    });
  }

  SetGetJobFaimlyApiCallResponse = (
    apicallId: string,
    responseJson: {
    message: string,
    data: Array<{id: number,created_at: string,updated_at: string,
        name_en: string,name_ar: string,account_id: number, description:string
      }>
    }
  ) => {
    if (this.GetJobFaimlyApiCallId === apicallId) {
      if (Array.isArray(responseJson.data) && responseJson.data.length !== 0) {
        const data = responseJson.data.map((item)=>{
          return{
            id: item.id,
            name_ar: item.name_ar,
            name_en: item.name_en,
            account_id: `${item.account_id}`,
            created_at: item.created_at,
            updated_at: item.updated_at,
            description: item.description
          }
        })
        this.setState({ JobFamilyData: data })
      }
    }
  }

  GetJobLevelApiCallFuncation = async () =>{
    this.GetJobLevelApiCallId = await this.formApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: "/bx_block_settings/job_levels"
    });
  }

  SetGetJobLevelApiCallResponse = (ApiCallId:string, responseJson: 
    {
      message: string,
      data: Array<
        {
          id: number, created_at: string, updated_at: string,
          name_en: string, name_ar: string, account_id: number,
          description: string,
        }>
    }

  ) => {
    if (this.GetJobLevelApiCallId === ApiCallId) {
      if (Array.isArray(responseJson.data) && responseJson.data.length !== 0) {
        const data = responseJson.data.map((item)=>{
          return{
            id: `${item.id}`,
            name_ar: item.name_ar,
            name_en: item.name_en,
            account_id: `${item.account_id}`,
            created_at: item.created_at,
            updated_at: item.updated_at,
            description: item.description
          }
        })
        this.setState({ JobLevelData: data })
      }
    }
  }

  TraslationFuncation = (key: string) => {
    if (this.props.i18n) {
      return this.props.i18n.t(key).toString()
    }
    else {
      return key
    }
  }

  EmployeeRolePaginationFuncation = (_e:React.ChangeEvent<unknown>, page: number) => {
    this.setState({
      EmployeeRolePagination: page
    },()=>{
      this.manageEmployeeGetApiCall()
    })
  }
  
  CandidateRolePaginationFuncation = (_e:React.ChangeEvent<unknown>, page: number) => {
    this.setState({
      CandidateRolePagination: page
    },()=>{
      this.manageCandidateGetApiCall()
    })
  }

  JobFamiyFormApiCall = async (values: {
    JobFamilyName: string,
    JobFamilyDescription: string
  }) => {
    const { JobFamilyName, JobFamilyDescription } = values;
    const { IsJobFamilyFormEdit, EditJobFamilyId } = this.state
    const body = {
      job_family: {
        "name_en": JobFamilyName,
        "name_ar": JobFamilyName,
        "description": JobFamilyDescription
      }
    }

    if (IsJobFamilyFormEdit) {
      this.JobFamilyFormEditApiCall = await this.formApiCall({
        method: "PUT",
        contentType: "application/json",
        endPoint: `/bx_block_settings/job_families/${EditJobFamilyId}`,
        body: body
      });
    } else {
      this.JobFamilyFormCreateApiCall = await this.formApiCall({
        method: "POST",
        contentType: "application/json",
        endPoint: `/bx_block_settings/job_families/`,
        body: body
      });
    }
  }

  ReSetJobFamilyForm = () =>{
    this.setState({
      jobFamilyPlusButton: false,
      JobFamilyName: "",
      JobFamilyDescription: "",
      EditJobFamilyId: '',
      IsJobFamilyFormEdit:false,
      JobFamilyFormReinitialize: true,
    },()=>{
      this.setState({
        JobFamilyFormReinitialize: false,
      })
    })
  }

  HandelEditJobFamily = (item:{
    id: number;
    name_ar: string;
    name_en: string;
    account_id: string;
    created_at: string;
    updated_at: string;
    description: string;
  }) => {
    this.setState({
      JobFamilyName: item.name_en,
      JobFamilyDescription: item.description,
      EditJobFamilyId: `${item.id}`,
      IsJobFamilyFormEdit: true,
      jobFamilyAction: -1
    })
    this.handleAddJobFamily()
  }

  OpenSuccessModelForProfile = () => {
    this.setState({
      SuccessModelForProfile:true
    })
  }
  
  CloseSuccessModelForProfile = () => {
    this.setState({
      SuccessModelForProfile:false,
      SuccessLabelText: '',
      SuccessUpdateOrCreate: "",
    })
  }

  JobFamilyEditAndCreateApiCallResponse = (apicallId: string, _responseJson: {}) => {
    if (this.JobFamilyFormCreateApiCall === apicallId) {
      this.ReSetJobFamilyForm()
      this.GetJobFaimlyApiCallFuncation()
      this.setState({
        SuccessLabelText: `${this.state.ActiveTabTextRender} `,
        SuccessUpdateOrCreate: this.TraslationFuncation("SettingsPage.saved"),
      },()=>{
        this.OpenSuccessModelForProfile()
      })
    }
    if (this.JobFamilyFormEditApiCall === apicallId) {
      this.ReSetJobFamilyForm()
      this.GetJobFaimlyApiCallFuncation()
      this.setState({
        SuccessLabelText: `${this.state.ActiveTabTextRender} `,
        SuccessUpdateOrCreate: this.TraslationFuncation("SettingsPage.updated"),
      },()=>{
        this.OpenSuccessModelForProfile()
      })
    }

  }

closeListIconPopup = () => {
  this.setState({ jobFamilyAction: -1 });
};
closedefineRatPop = () => {
  this.setState({ defineRatingAction: -1 });
};
closeDataProps =()=>{
  this.setState({
    jobFamilyAction: -1
  });
}

  // Customizable Area End
}
