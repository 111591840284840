// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";

interface UserProfile {
    error?: string;
    errors: Array<{
        token: string;
    }>,
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            company_name: string;
            "website": string;
            "linkedin": string;
            "twitter": string;
            "country": string;
            "account_id": number;
            "photo": string;
            "full_name": string;
            "date_of_birth": string;
            "email": string;
        }
    }
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface KeySkillAttributes {
    id: number;
    skill_name: string;
}

interface KeySkill {
    id: string; // Note: id is a string in the JSON
    type: string;
    attributes: KeySkillAttributes;
}

interface KeySkillsData {
    data: KeySkill[];
}

interface JobLevel {
    id: number;
    name: string;
}

interface JobFamily {
    id: number;
    name: string;
}

interface JobExperience {
    id: number;
    name: string;
}

interface ChatGPTResponse {
    position: string;
    department: string;
    job_level: string;
    job_experience: string;
    job_summary: string;
    key_responsibilities: string[];
    key_skills: string[];
    keywords: string[];
}

interface JobDescriptionAttributes {
    id: number;
    created_at: string; // ISO 8601 date string
    job_title: string;
    department: string;
    job_summary: string;
    key_words: string[];
    key_skills: KeySkillsData;
    job_level: JobLevel;
    job_family: JobFamily;
    job_experience: JobExperience;
    chatgpt_response: ChatGPTResponse;
}

interface JobDescriptionData {
    id: string; // Note: id is a string in the JSON
    type: string;
    attributes: JobDescriptionAttributes;
}

interface ViewJobDescription {
    data: JobDescriptionData;
}



// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    children?: ReactNode;
    selectedMenu?: string;
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    profileId: string;
    errorMsg: string;
    userProfileData: UserProfile;
    sideMenuVisible: boolean;
    isMdUp: boolean;
    languageDropdown: boolean;
    profileDropdown: boolean;
    selectedLanguage: string;
    loader: boolean;
    ShowJobDescriptChatGpt: {
        key_skills: Array<string>,
        key_responsibilities: Array<string>,
        key_words: Array<string>,
        position: string,
        department: string,
        job_level: string,
        job_experience: string,
        job_summary: string,
    },
    viewedId: string;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewJobDescriptionController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    GetJobDescrptionDataApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            profileId: "",
            errorMsg: "",
            userProfileData: {} as UserProfile,
            sideMenuVisible: false,
            isMdUp: false,
            languageDropdown: false,
            profileDropdown: false,
            selectedLanguage: "English",
            loader: false,
            ShowJobDescriptChatGpt: {
                key_skills: [],
                key_responsibilities: [],
                key_words: [],
                position: '',
                department: '',
                job_level: '',
                job_experience: '',
                job_summary: '',
            },
            viewedId: "",
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.SetViewJobDescriptApiCallResponse(apiRequestCallId, responseJson);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        const ViewJobId = await getStorageData('ViewJobId')
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        })
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        this.GetJobDescriptionDataApiCallFuncation(ViewJobId)
    }

    NavigateToAnyPage = (pageName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    ViewJobDescriptionApiCall = async (id:string) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json",
        };
        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetJobDescrptionDataApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getJobDescDetailsAPiEndPoint}/${id}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    };

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        }
    }
    
    ConditionForLanguage = (TrueCondition: string, FalseCondition: string) => {
        if (this.state.selectedLanguage.toLowerCase() === "english") {
            return TrueCondition
        } else {
            return FalseCondition
        }
    }

    BackToJobDescription = () => {
        this.NavigateToAnyPage("Cfchatgptintegration9")
        removeStorageData("ViewJobId");
    }

    GetJobDescriptionDataApiCallFuncation = async (id: string | null) => {
        if (id) {
            this.setState({ loader: true })
            this.ViewJobDescriptionApiCall(id)
        } else {
            this.BackToJobDescription()
        }
    }

    SetViewJobDescriptApiCallResponse = (ApiCallId:string,responseJson:ViewJobDescription) =>{
        if (this.GetJobDescrptionDataApiCallId === ApiCallId) {
            this.setState({
                ShowJobDescriptChatGpt:{
                    department: responseJson.data.attributes.department,
                    job_level: responseJson.data.attributes.job_level.name,
                    job_experience: responseJson.data.attributes.job_experience.name,
                    job_summary: responseJson.data.attributes.job_summary,
                    key_skills: responseJson.data.attributes.key_skills.data.map((item)=> item.attributes.skill_name),
                    key_words: responseJson.data.attributes.key_words,
                    key_responsibilities: responseJson.data.attributes.chatgpt_response.key_responsibilities,
                    position: responseJson.data.attributes.job_title,
                  },
                  loader: false
            })
        }
    }

    CloseSuccessModel = () => {
        removeStorageData("ViewJobId");
        this.NavigateToAnyPage("Cfchatgptintegration9")
    }

    // Customizable Area End

}