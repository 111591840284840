import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    CircularProgress,
    MenuItem,
    Menu,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions
} from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { createNewQuestion, generateAssement, uploadQuestions, emptyDashboardImg, allDownArrow, deleteQuestionBank } from "./assets"
// Customizable Area End

import QuestionBankDashboardController, {
    Props,
} from "./QuestionBankDashboardController";

export default class QuestionBankDashboard extends QuestionBankDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    deleteSuccess = () => {
        return (
            <Dialog
                onClose={this.handleCloseDelete}
                open={this.state.opendialog}
                PaperProps={{
                    sx: {
                        borderRadius: "8px 8px 32px 8px",
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper sx={{ display: "flex", alignItems: "center", padding: "0" }}>
                        <Typography className="deleteQueDialogTitle">
                            Delete Details
                        </Typography>
                        <IconButton
                            data-test-id="close_delete_dialog"
                            aria-label="close"
                            onClick={this.handleCloseDelete}
                            className="deleteDialogClose"
                            sx={{
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30,
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="deleteImageBox">
                            <img src={deleteQuestionBank.default}
                                alt="Success Image"
                                height={144}
                                width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            fontFamily: "Urbanist",
                            padding: {
                                xs: "14px 14px",
                                sm: "14px 64px"
                            },
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        Are you sure you want to delete this QuestionBank?
                    </Typography>
                    <Typography
                        sx={{
                            padding: "0px 68px",
                            fontFamily: "Urbanist",
                            fontWeight: 400,
                            lineHeight: "26px",
                            color: "#0F172A",
                            fontSize: "18px",
                            textAlign: "center"
                        }}>
                        You won't be able to access them again.
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    sm: "flex-end"
                                }
                            }}
                            className={`${this.state.selectedLanguage.toLowerCase() === "english" ?
                                "deleteButtonsBox" : "deleteButtonsArabic"}`}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleCloseDelete}
                                data-test-id="cancel_delete_dialog"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "cancelDeleteButton" : "cancelDeleteArabic"}`}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => this.deleteQuestionBankApiCall(this.state.deleteRowId)}
                                data-test-id="delete_que_dialog"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deleteButton" : "deleteButtonArabic"}`}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    noDataScreen = () => {
        return (
            <NoDataWrapper>
                <Box>
                    <Grid container className="gridContainer">
                        <Grid item xs={12}>
                            <Box className="noDataImageBox">
                                <img src={emptyDashboardImg.default} />
                                <Typography className="ohNoDataMainText">
                                    Ohh No, No data found.
                                </Typography>
                                <Typography className="noDataText">
                                    It looks like there’s nothing here yet.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </NoDataWrapper>
        )
    }

    exportAllTab = () => {
        return (
            <Grid item xs={12} md={6} xl={6}>
                <Button
                    className="exportAllButton"
                    data-test-id="export_all_btn"
                >
                    <Typography className="exportAllText">
                        Export All
                    </Typography>
                </Button>
            </Grid>
        )
    }

    AllBtnTab = () => {
        return (
            <Grid item xs={12} md={5} xl={5} >
                <Button
                    className="allButton"
                    data-test-id="all_question_btn"
                >
                    <Box className="allTextArrowBox">
                        <Typography className="allBtnText">
                            All
                        </Typography>
                        <img src={allDownArrow.default} />
                    </Box>
                </Button>
            </Grid>
        )
    }

    questionTable = () => {
        return (
            <Wrapper>
                <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                    <CardContent>
                        {this.state.questionListData.length  ? 
                        <>
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                <Grid item xs={11} sm={3.5} md={3.5} lg={4.5}>
                                    <Typography className="recentReultTitle">
                                        Recent Results
                                    </Typography>
                                </Grid>

                                    <Grid item xs={11} sm={5} md={5.5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: {
                                                xs: "flex-start",
                                                md: "flex-end",
                                                xl: "flex-end"
                                            },
                                            marginRight: "16px"
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {this.exportAllTab()}
                                            {this.AllBtnTab()}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!this.state.loader && this.generatedQuestionsTable()}
                            </> :
                            !this.state.loader && this.noDataScreen()
                        }
                        {this.state.loader && (
                            <Box className="loaderOuter">
                                <Box className="loader-inner">
                                    <CircularProgress />
                                </Box>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Wrapper>
        )
    }

    createNewQuestions = () => {
        const selectedSideTab = this.state.selectedTab === "CreateNewQuestion"
        return (
            <Wrapper sx={{ paddingTop: "24px" }}>
                <Box
                    onClick={() => this.handleTabSelction("CreateNewQuestion")}
                    className={selectedSideTab ? "selectedAction" : "notSelectedTab"}
                    data-test-id="create_question_tab"
                >
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headingSubGrid">
                            <img
                                src={createNewQuestion.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='headingText'>
                                Create New Question
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                            <Typography className="headingSubText">
                                Design a tailored question bank by selecting competencies, question types, and difficulty levels to fit your assessment needs.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    generateAssessment = () => {
        const selectedSideTab = this.state.selectedTab === "GenerateAssessment"
        return (
            <Wrapper>
                <Box
                    onClick={() => this.handleTabSelction("GenerateAssessment")}
                    className={selectedSideTab ? "selectedAction" : "notSelectedTab"}
                    data-test-id="generate_assessment_tab"
                >
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headingSubGrid">
                            <img
                                src={generateAssement.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='headingText'>
                                Generate Assessment
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                            <Typography className="headingSubText">
                                Quickly create assessments by selecting questions from your question bank, adjusting difficulty levels, and setting specific objectives.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    uploadQuestions = () => {
        const selectedSideTab = this.state.selectedTab === "UploadQuestionsManually"
        return (
            <Wrapper>
                <Box
                    onClick={() => this.handleTabSelction("UploadQuestionsManually")}
                    className={selectedSideTab ? "selectedAction" : "notSelectedTab"}
                    data-test-id="upload_question_tab"
                >
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headingSubGrid">
                            <img
                                src={uploadQuestions.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='headingText'>
                                Upload Questions Manually
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                            <Typography className="headingSubText">
                                Manually add and configure individual questions, setting all necessary parameters for each entry.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    manageQuestions = () => {
        const selectedSideTab = this.state.selectedTab === "ManageQuestions"
        return (
            <Wrapper>
                <Box
                    onClick={() => this.handleTabSelction("ManageQuestions")}
                    className={selectedSideTab ? "selectedAction" : "notSelectedTab"}
                    data-test-id="manage_question_tab"
                >
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headingSubGrid">
                            <img
                                src={createNewQuestion.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='headingText'>
                                Manage Questions
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                            <Typography className="headingSubText">
                                Edit, organize, and review your existing questions to ensure they align with your assessment goals and standards.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    navigationMenu = () => {
        return (
            <NavigationWrapper>
                <Card className="navigationCard">
                    <Grid item xs={12}>
                        <Typography className="quickActionText">
                            Quick Actions
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {this.createNewQuestions()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.generateAssessment()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.uploadQuestions()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.manageQuestions()}
                    </Grid>
                </Card>
            </NavigationWrapper>
        )
    }

    generatedQuestionsTable = () => {
        const viewMoreText = `Organisational Awareness, Behavioral Competencies, Organisational Awareness, Core Competencies, Organisational Awareness`;
        return (
            <TableContainer sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="questionTilteText">
                                <Box className="tableCellBox">
                                    Job Description
                                </Box>
                            </TableCell>

                            <TableCell className="questionTilteText">
                                <Box className="tableCellBox">
                                    Competency
                                </Box>
                            </TableCell>
                            <TableCell className="questionTilteText">
                                <Box className="tableCellBox">
                                    Assigned Questions
                                </Box>
                            </TableCell>
                            <TableCell
                                data-test-id="delete_selected"
                                className="questionTilteText">
                                Action
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.questionListData?.map((row, index) => {
                            const rowId = row?.data?.id || "";
                            return (
                                <TableRow key={index}>
                                    <TableCell className="questionTilteText">
                                        {row.data?.attributes.competencies_and_question_counts[0].job_title ||
                                            "No job title available"
                                        }
                                    </TableCell>

                                    <TableCell className="questionTilteText">
                                        {row.data?.attributes.competencies_and_question_counts[0].competencies ||
                                            "No competency available"
                                        }
                                        <Typography
                                            className="viewMore"
                                            data-test-id="view_more"
                                            onClick={(event) => this.handleViewMoreMenuOpen(event, rowId)}
                                        >
                                            View More
                                        </Typography>
                                        <Menu
                                            data-test-id="view_more_close"
                                            anchorEl={this.state.viewMoreAnchorEl}
                                            open={Boolean(this.state.viewMoreAnchorEl) && this.state.selectedViewRowId === rowId}
                                            onClose={this.handleViewMoreMenuClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    borderRadius: "8px",
                                                    border: "1px solid #E2E8F0",
                                                    padding: "0px 6px"
                                                }
                                            }}
                                            sx={{
                                                ml: -3
                                            }}
                                        >
                                            <MenuItem
                                                sx={{
                                                    fontFamily: "Urbanist",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    backgroundColor: "E2E8F0",
                                                    color: "#0F172A",
                                                    "&:hover": {
                                                        backgroundColor: "E2E8F0",
                                                    },
                                                }}
                                                data-test-id="view_question"
                                            >
                                                <span>
                                                    {viewMoreText.split(', ').map((line, moreIndex) => (
                                                        <span key={moreIndex}>
                                                            {line},
                                                            <br />
                                                        </span>
                                                    ))}
                                                </span>
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>

                                    <TableCell className="questionTilteText">
                                        {row.data?.attributes.competencies_and_question_counts[0].total_questions_count}
                                    </TableCell>

                                    <TableCell>
                                        <IconButton
                                            data-test-id="more_icon"
                                            onClick={(event) => this.handleMenuOpen(event, rowId)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            data-test-id="more_menu_close"
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl) && this.state.selectedRowId === rowId}
                                            onClose={this.handleMenuClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    borderRadius: "8px",
                                                    border: "1px solid #E2E8F0",
                                                }
                                            }}
                                            sx={{
                                                mt: 4,
                                                ml: -3,
                                            }}
                                        >
                                            <MenuItem
                                                sx={{
                                                    padding: "8px 40px 8px 16px",
                                                    fontFamily: "Urbanist",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    color: "#0F172A"
                                                }}
                                                data-test-id="view_details"
                                                onClick={() => this.handleView(rowId)}
                                            >
                                                View details
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#F87171", borderRadius: "8px" }}
                                                data-test-id="delete_question"
                                                onClick={() => { this.handleDelete(rowId) }}
                                            >
                                                Delete details
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>
                            {this.navigationMenu()}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6.8}>
                            {this.questionTable()}
                        </Grid>
                    </Grid>
                    {this.deleteSuccess()}
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    width: "100vw",
    marginTop: "32px",
})
export const NoDataWrapper = styled(Box)({
    width: "100%",
    maxHeight: "700px",
    height: "75%",
    padding: "24px 24px 24px 0px",
    "& .navigationCard": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        padding: "24px 20px 24px 30px"
    },
    "& .noDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    },
    "& .noDataText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        lineHeight: "26px",
        color: "#8A96A8",
        fontSize: "18px",
        textAlign: "center",
        width: "60%"
    },
    "& .ohNoDataMainText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        color: "#000104",
        textAlign: "center"
    },
    "& .gridContainer": {
        height: "100%"
    }
})
export const Wrapper = styled(Box)({
    padding: "24px 12px 12px 0px",
    cursor: "pointer",
    "& .loaderOuter": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh"
    },
    "& .selectedAction": {
        background: "#EEEEEE",
        padding: "0px 6px 10px 0px"
    },
    "& .notSelectedTab": {
        padding: "0px 6px 10px 0px"
    },
    "& .headingText": {
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        color: "#044352"
    },
    "& .headingSubText": {
        marginTop: "10px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#334155"
    },
    "& .headingSubGrid": {
        display: "flex",
        justifyContent: "end"
    },
    "& .recentReultTitle": {
        fontSize: "25px",
        lineHeight: "30px",
        color: "#000104",
        fontWeight: 600,
        fontFamily: "Urbanist",
        marginLeft: "16px"
    },
    "& .allTextArrowBox": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    "& .allBtnText": {
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        color: "#64748B",
        textTransform: "none"
    },
    "& .exportAllButton": {
        width: "100%",
        borderRadius: "4px",
        height: "58px",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        }
    },
    "& .exportAllText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#044352",
        fontWeight: 700,
        textTransform: "none",
    },
    "& .allButton": {
        background: "#F1F5F9",
        height: "58px",
        width: "100%",
        borderRadius: "4px",
        padding: "16px 30px",
        position: "relative",
        border: "1px solid #CBD5E1"
    },
    "& .questionTilteText": {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        color: "#044352",
        paddingBottom: "30px",
        paddingTop: "30px"
    },
    "& .viewMore": {
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        color: "#64748B",
        textDecoration: "underline",
        textDecorationColor: "#64748B"
    },
    "& .deleteQueDialogTitle": {
        fontSize: "24px",
        lineHeight: "32px",
        color: "#1E1E1E",
        fontFamily: "Urbanist",
        fontWeight: 700,
        margin: "8px 16px"
    },
    "& .deleteButton": {
        fontWeight: 700,
        borderRadius: "8px",
        gap: "8px",
        opacity: "0px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        height: "56px",
        padding: "16px 34px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .deleteButtonArabic": {
        padding: "16px 39.5px 16px 36.5px",
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        marginRight: "16px",
        fontWeight: 700,
        height: "56px",
        gap: "8px",
        fontFamily: "Urbanist",
        background: "#044352",
        textTransform: "none",
        width: "120px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .cancelDeleteButton": {
        padding: "16px 34px",
        fontWeight: 700,
        borderRadius: "8px",
        marginLeft: "16px",
        gap: "8px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        height: "56px",
        opacity: "0px",
        background: "#F1F5F9",
        textTransform: "none",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .cancelDeleteArabic": {
        padding: "16px 39.5px 16px 36.5px",
        background: "#F1F5F9",
        borderRadius: "8px",
        height: "56px",
        marginRight: "16px",
        gap: "8px",
        fontFamily: "Urbanist",
        textTransform: "none",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .deleteButtonsArabic": {
        margin: "16px 0px",
        paddingLeft: "16px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .deleteButtonsBox": {
        display: "flex",
        justifyContent: "flex-end",
        margin: "16px 0px",
        paddingRight: "16px"
    },
    "& .deleteImageBox": {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0px"
    },
    "& .deleteDialogClose": {
        color: "#334155",
        position: 'absolute',
        top: 16
    },
})
export const NavigationWrapper = styled(Box)({
    padding: "24px 24px 24px 0px",
    "& .navigationCard": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        padding: "24px 20px 24px 30px"
    },
    "& .quickActionText": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        color: "#000104"
    }

})
// Customizable Area End