import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React, { ReactNode } from "react";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";

interface UserProfile {
  error?: string;
  errors:Array<{
    token: string;
  }>,
  data?: {
    id: string;
    type: string;
    attributes: {
      id: string;
      company_name: string;
      "website": string;
      "linkedin": string;
      "twitter": string;
      "country": string;
      "account_id": number;
      "photo": string;
      "full_name": string;
      "date_of_birth": string;
      "email": string;
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?: ReactNode;
  selectedMenu?: string;
  i18n?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  profileId: string;
  errorMsg: string;
  userProfileData: UserProfile;
  sideMenuVisible: boolean;
  isMdUp: boolean;
  languageDropdown: boolean;
  profileDropdown: boolean;
  selectedLanguage: string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  mediaQueryList: MediaQueryList | null = null;
  showProfileApiCallId: string = "";
  ChangeLandApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      token: "",
      profileId: "",
      errorMsg: "",
      userProfileData: {} as UserProfile,
      sideMenuVisible: false,
      isMdUp: false,
      languageDropdown: false,
      profileDropdown: false,
      selectedLanguage: "English",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.showProfileApiCallId) {
        this.handleProfileApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();   

    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguage: language });

    this.mediaQueryList = window.matchMedia('(min-width:900px)');

    this.handleMediaQueryChange(this.mediaQueryList);
    if (this.mediaQueryList.addEventListener) {
      this.mediaQueryList.addEventListener('change', this.handleMediaQueryChange);
    }

    const signInResponse = await getStorageData("signInResponse");
    const parsedSignInResponse = JSON.parse(signInResponse)
    this.setState({
      token: parsedSignInResponse.meta?.token,
      profileId: parsedSignInResponse.meta?.profile_id
    })
    this.showProfileApiCall()
    this.ChangeLang()
  }

  async componentWillUnmount() {
    if (this.mediaQueryList?.removeEventListener) {
      this.mediaQueryList.removeEventListener('change', this.handleMediaQueryChange);
    }
  }

  handleProfileApiResponse = (responseJson: UserProfile) => {
    if (responseJson && !responseJson.error) {
      this.setState({ userProfileData: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
    if (Array.isArray(responseJson.errors)) {      
      if (responseJson.errors[0].token === "Token has Expired") {
        this.NavigateToAnyPage("EmailAccountLoginBlock")
        removeStorageData("signInResponse")
      } 
    }    
  }

  showProfileApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showProfileApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showProfileApiEndPoint}/${this.state.profileId}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
    this.setState({ isMdUp: event.matches }, () => {
      if (!this.state.isMdUp) {
        this.setState({ sideMenuVisible: false });
      } else {
        this.setState({ sideMenuVisible: true });
      }
    });
  };

  toggleSideMenu = () => {
    this.setState({ sideMenuVisible: !this.state.sideMenuVisible })
  }

  toggleLanguageDropdown = () => {
    this.setState((prevState) => ({
      languageDropdown: !prevState.languageDropdown,
    }));
  };

  ChangeLang = () => {
    
    const headers = {
      "token": this.state.token
    };
    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ChangeLandApiCallId = getUserStatusMsg.messageId;
    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_settings/languages/id?language=${this.state.selectedLanguage === "English" ? "en" : "ar"}`
    );
    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  closeDropdown = () => {
    this.setState({ languageDropdown: false });
  };

  toggleProfileDropdown = () => {
    this.setState((prevState) => ({
      profileDropdown: !prevState.profileDropdown,
    }));
  };

  closeProfileDropdown = () => {
    this.setState({ profileDropdown: false });
  };

  languageChange = (language: string) => {
    setStorageData("language", language);
    location.reload();
  };

  navigateToUserProfile = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileBasic");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  navigateToSetting = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  NavigateToAnyPage = (pageName: string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  // Customizable Area End

}